import React,{useEffect,useRef, useState} from  'react';
import { Container, Row, Button,Form ,Col} from 'react-bootstrap';
import InputMask from 'react-input-mask';
import { Formik } from 'formik';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { searchByOrderNo } from '../../api/service/generalService';
import OrderSearchList from './orderSearchList';
import ReactGA from 'react-ga4';


function OrderInQuiry() {
    const form = useRef(null);
    const [orderList, setOrderList] = useState();


    useEffect(() => {
        const loggedIn =  JSON.parse(localStorage.getItem('loggedIn'));
        document.body.classList.add('sales-quiry');
        if(loggedIn){
            // if(cityList.length === 0) {
            //     getCityList();

            // }
        }
    });

    useEffect(() => {
        //ReactGA.pageview(window.location.pathname + window.location.search);
        ReactGA.send({ hitType: "pageview", page:window.location.href, title: "Sipariş Durum Sorgulama" });
        return () => {
        document.body.classList.remove('sales-quiry');
        };
    }, []);

    const handleSubmit = async (event) => {
        if(event.SiparisNo !== "" && event.CepTel !== "") {
            const res = await searchByOrderNo(event.SiparisNo.trim());
            if(!res.errStatus) {
                setOrderList(res.data);
            }
        }
        else {
            

        }
    };

    if(orderList) {
        if(orderList.length > 0)
            return(
                <>
                <Container fluid className="sales-quiry-full-part">
                    <Container className="sales-quiry-normal-part">
                            <h1>Sipariş Sorgulama</h1>
                            <Row>
                                <Col md={12} className="sales-quirt-grid-part">
                                        <OrderSearchList  orderList={orderList}/>
                                </Col>
                            </Row>
                    </Container>
                </Container>
                </>
            );
        else{
            return(
                <Container fluid className="sales-quiry-full-part">
                <Container className="sales-quiry-normal-part">
                        <h1>Sipariş Sorgulama</h1>
                        <Row>
                            <Col md={12} className="sales-quirt-grid-part">
                                    Sipariş bulunamadı
                            </Col>
                        </Row>
                </Container>
            </Container>
            );
        }
    }
    return(
        <>
            <Container fluid className="sales-quiry-full-part">
    
                    <Container className="sales-quiry-normal-part">
                        <h1>Sipariş Sorgulama</h1>
                        <Row>
                            <Col md={12} className="sales-quirt-grid-part">
                                <p>Sipariş numaranızı ve sipariniz sırasında belirttiğiniz telefon numaranızı kullanarak siparişiniz hakkında bilgi alabilirsiniz.
                                   <br/> Sitemize üyeliğiniz var ise e-posta ve parola bilginiz ile giriş yaparak da detaylı bilgi edinebilirsiniz.
                                </p>
                                <GoogleReCaptchaProvider
                                        reCaptchaKey="AIzaSyBkP034v_x39IgV4Sqh3WY63NOM619cYWY"
                                        scriptProps={{
                                        async: false, // optional, default to false,
                                        defer: false, // optional, default to false
                                        appendTo: "head", // optional, default to "head", can be "head" or "body",
                                        }}
                                    >
                                <Formik
                                onSubmit={handleSubmit}
                                //  validationSchema={schema}
                                initialValues={{
                                    SiparisNo:'',
                                    CepTel:'',
                                }}
                                >
                                {({
                                    handleSubmit,
                                    handleChange,
                                    values,
                                    touched,
                                    errors,
                                }) => (
                                <Form ref={form} noValidate onSubmit={handleSubmit}>
                                    <Row className="mb-12 address-edit-input-box">
                                        <Form.Group as={Col} md="12" controlId="name" className="address-edit-input">
                                        <Form.Control
                                                type="text" placeholder="Sipariş numarası giriniz"
                                                name="SiparisNo"
                                                value={values.SiparisNo}
                                                onChange={handleChange}
                                                isValid={touched.AdSoyad && !errors.AdSoyad}
                                                
                                            /> 
                                        <Form.Control.Feedback type="invalid">
                                            {errors.username}
                                            </Form.Control.Feedback>
                                    </Form.Group>
                                    
                                   
                                        <Form.Group as={Col} md="12" controlId="phonenumber" className="address-edit-input">
                                            <InputMask  pattern={window.screen.width < 478 ? "[0-9]*" :''} style={{padding:'10px'}} name="CepTel" placeholder="* Telefon numaranız" mask={window.screen.width < 478 ?"09999999999" :"0 (999) 999 99-99"}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                        </Row>
                                    
                                        <Button variant="primary" type="submit" style={{float:'right'}}>
                                            Sipariş Sorgula
                                        </Button>
                                    </Form>
                                )}
                                </Formik>
                                </GoogleReCaptchaProvider>

                            </Col> 
                        </Row>
                    </Container>
       
            </Container>

        </>
    );
}

export default OrderInQuiry;