import React, { useEffect, useState } from 'react';
import { productBaseUrl2} from '../../../constans'
import { PropTypes } from 'prop-types';
import { connect } from "react-redux";
import {
    freeAddToCart,removeToCart,removeFreeProductItemToCart
  } from '../../../action/productAction'

import { Button } from 'react-bootstrap';//added 17-09-23
import { Dec, Inc } from '../../../constans/svgExport';//added 17-09-23

const FreeProduct = (props) => {
    const [incVisible,setIncVisible] = useState(false);
    const [produtUnit, setProdutUnit] = useState(0);
    const [disableCartItem, setDisableCartItem] = useState(false);

    useEffect(() => {
        const getLocalCart =JSON.parse(localStorage.getItem('cartFreeProduct'));
        
        if(getLocalCart !== null) {
            const freeProductCart = getLocalCart.find(
                (product) => product.ProductId === props.urunID
              );
              if(freeProductCart !== undefined) {
                setProdutUnit(freeProductCart.qty);
                setIncVisible(true);
            }
        }
    },[props])

    const updateHediye=()=>{
        var totalPrice=0;
        const freeCard = JSON.parse(localStorage.getItem('cartFreeProduct'));
        if(freeCard!=null){
            freeCard.forEach((product) => {
                totalPrice = totalPrice + (product.Price * product.qty);
            })
            props.handleFreeProdutPrice(totalPrice);//arr.reduce(reducer)
        }else
        {
            props.handleFreeProdutPrice(0); 
        }
        
        
    }

    const handleCounterClick = (id) => {
        const freeCard = JSON.parse(localStorage.getItem('cartFreeProduct'));
       
        if(freeCard !== null) {
            let total = 0 ;
            freeCard.forEach(element => {
                total += (element.Price * element.qty);
            });
            if(total  + props.fiyat  <= props.hediyeSepetToplam) {
                setProdutUnit(produtUnit + 1);
                setIncVisible(true);
                props.freeAddToCart(id,props.hediyeSepetToplam); 
            }
            else {
                const reducer = (previousValue, currentValue) => previousValue + currentValue;
                const arr = freeCard.map((e) => e.qty * e.Price);
                //props.handleFreeProdutPrice(arr.reduce(reducer));
                setDisableCartItem(true);
            }
        }
        else {
            setProdutUnit(produtUnit + 1);
            setIncVisible(true);
            props.freeAddToCart(id,props.hediyeSepetToplam);
        }
         updateHediye();
        
    }


    const handleCounterDecClick = (id) => {
        
        const freeCard = JSON.parse(localStorage.getItem('cartFreeProduct'));
        if( produtUnit !== 0) {
            if(freeCard !== null) {
                let total = 0 ;
                freeCard.forEach(element => {
                    total += (element.Price * element.qty);
                });
                if(total <= props.hediyeSepetToplam) {
                    setProdutUnit(produtUnit - 1);
                    setIncVisible(true);
                    props.removeFreeProductItemToCart(id,props.hediyeSepetToplam);
                }
                else {
                    const reducer = (previousValue, currentValue) => previousValue + currentValue;
                    const arr = freeCard.map((e) => e.qty * e.Price);
                    //props.handleFreeProdutPrice(arr.reduce(reducer));
                    setDisableCartItem(true);
                }
            }
            else {
                setProdutUnit(produtUnit - 1);
                setIncVisible(true);
                props.removeFreeProductItemToCart(id,props.hediyeSepetToplam);
            }
          
        }
        else {
            setDisableCartItem(false);
        }
      
        updateHediye();
    }

    return(
        <>
                    <div className="free-product-cart">
                        <img src={productBaseUrl2 + props.imageUrl} alt={props.imageUrl} />
                            <div className="free-product-name">
                                <span>{props.productName}</span>
                                <span>{props.fiyat} TL</span>
                            </div>
                            { <div className="product-add-to-cart">
                                <div className="numbers-row">
                                    <Button style={{display: !incVisible ? "none" : "flex"}} className="dec"  onClick={() => handleCounterDecClick(props.urunID)}>
                                            {Dec()}
                                    </Button>
                                    <span style={{display: !incVisible ? "none" : "block"}}>
                                        {produtUnit}</span>
                                    <Button  className="inc"  disabled={disableCartItem} onClick={() => handleCounterClick(props.urunID)}>
                                        {Inc()}
                                    </Button>
                                </div>
                            
                        </div>  }
                    </div>
        </>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        freeAddToCart: (id,totalPrice) => dispatch(freeAddToCart(id,totalPrice)),
        removeToCart: id => dispatch(removeToCart(id)),
        removeFreeProductItemToCart: id => dispatch(removeFreeProductItemToCart(id)),

    }
  }
  const mapStateToProps = (state) => ({
    cart: state.productList.cart,
   
  });

  FreeProduct.propTypes = {
    freeAddToCart: PropTypes.func.isRequired,
    removeToCart: PropTypes.func.isRequired,
    cart:PropTypes.array.isRequired
    };

 export default connect(mapStateToProps,mapDispatchToProps)(FreeProduct);
