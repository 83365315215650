import React, {useState,useRef,useEffect} from 'react';
import { Button,Form,Row,Col,Toast,Modal} from 'react-bootstrap';
import { Formik ,Field} from 'formik';
import InputMask from 'react-input-mask';
import {directCheckoutPayment , serchByOrderStatus,searchByOrderDetail} from '../../../api/service/cartServices'; 
import MesafeliSatis from '../../../views/Contact/mesafeliSatis';
import ReactGA from 'react-ga4';
import { useHistory } from 'react-router';

function DirectPayment(props) {
        const form = useRef(null);
        const [showMessage, setShowMessage] = useState(false);
        const [cardValue, setCardValue] = useState('');
        const [cardMask, setCardMask] = useState('');
        const [selectMonth, setSelectMonth] = useState();
        const [selectedDistrict, setSelectedDistrict] = useState();
        const [showModalShow, setModalShow] = useState();
        const history = useHistory()
        const [show, setShow] = useState(false);
        let interval = null;

        useEffect(() => {
   
            //ReactGA.pageview(window.location.pathname + window.location.search);
            ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Doğrudan Ödeme" });

        });

        useEffect(() => {
            return () => {
                clearInterval(interval);
            };
        }, [interval]);
        
        const handleCardChange = (event) => {
            var value = event.target.value;
            var newState = {
                mask: '9999-9999-9999-9999',
                value: value
            };
            if (/^3[47]/.test(value)) {
                newState.mask = '9999-999999-99999';
            }
            setCardMask(newState.mask);
            setCardValue(newState.value);    
        }
    
    


        const handleMonthChange = async (e) => {
            setSelectMonth(e.target.value);
            
        }
        const handleClose = () => setModalShow(false);

        const handleDistrictChange = (e) => {
            setSelectedDistrict(e.target.value);
        }

        const handleSubmit = async (event) => {
            event.expirationMonth = selectMonth;
            event.expirationYear = selectedDistrict;
            event.creditCardNumber = cardValue.replaceAll('-','');
            event.orderId = props.order.orderId;
            const res = await directCheckoutPayment(event);
            if(!res.errStatus){
                let iframe = document.createElement("iframe");
                let encodedUrl = encodeURI(res.data.content);
                encodedUrl = encodedUrl.replace(/#/g, "%23");
                iframe.src = "data:text/html;charset=utf-8," + encodedUrl;
                setModalShow(true);
                document.getElementById("bank-data-content").appendChild(iframe);
                    interval = setInterval( async () => {
                        const orderStatus = await serchByOrderStatus(event.orderId); 
                        if(orderStatus.data === 3){
                            const orderDetail = await searchByOrderDetail(event.orderId)
                            localStorage.setItem("orderResult",JSON.stringify({siparis :orderDetail.data}));
                            localStorage.setItem('orderId',event.orderId);
                            clearInterval(interval);
                            history.push('/order/siparis-verildi');
                            
                        }
                        else if(orderStatus.data === 2) {
                            const orderDetail = await searchByOrderDetail(event.orderId)
                            localStorage.setItem("orderResult",JSON.stringify({siparis :orderDetail.data}));
                            localStorage.setItem('orderId',event.orderId);  
                            clearInterval(interval);
                            history.push('/order/resulterror');
                        }  
                        /*        
                        if(orderStatus.data === 3){
                            localStorage.setItem('orderId',event.orderId);
                            clearInterval(interval);
                            history.push('/order/siparis-verildi');
                            
                        }
                        else if(orderStatus.data === 2) {
                            localStorage.setItem('orderId',event.orderId);
                            clearInterval(interval);
                            history.push('/order/resulterror');
                        }*/                   
                
                }, 1000);
            }            
        };

        const kartDateTime = () => {
            const kartDate = []
            for(let y = new Date().getFullYear(); y < new Date().getFullYear() + 15; y++)
            {
                if(y !== undefined){
                    kartDate.push(<option value={y.toString().substr(2,2)} key={y}>{y.toString().substr(2,2)}</option>)

                }
            }
            return kartDate;
                
        }
        const handleShow = () => {
            setShow(true)
        }
        const handleContractClose = () => {
            setShow(false)
        }

    return(
        <>
            <Toast onClose={() => setShowMessage(false)} show={showMessage} delay={3000} autohide>
            <Toast.Header>
                <strong className="mr-auto">Şahin724</strong>
                <small>Uyarı Mesajı</small>
            </Toast.Header>
            {/* <Toast.Body>{errorMessage}</Toast.Body> */}
            </Toast>
            <label className="form-title">Kart Bilgilerinizi Giriniz</label>
            <Formik
                        onSubmit={handleSubmit}
                        initialValues={{
                            AdSoyad:'',
                            creditCardNumber: '',
                            cvc: '',
                            isContractAccept: true,
                            cartType:1,
                            
                        }}
                        >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors,
                        }) => (
                        <Form ref={form} noValidate onSubmit={handleSubmit}>
                            <Row className="mb-12 checkout-edit-input-box">
                                <Form.Group as={Col} md="12" controlId="name" className="checkout-edit-input">
                                
                                <Form.Control
                                        type="text" placeholder="Kartınızın üzerindeki isim"
                                        name="AdSoyad"
                                        value={values.AdSoyad}
                                        onChange={handleChange}
                                        isValid={touched.AdSoyad && !errors.AdSoyad}
                                        
                                    /> 
                                <Form.Control.Feedback type="invalid">
                                    {errors.username}
                                    </Form.Control.Feedback>
                            </Form.Group>                                
                            <Form.Group as={Col} md="12" controlId="phonenumber" className="checkout-edit-input ">
                                   <InputMask pattern="[0-9]*" placeholder="Kredi Kart Numaranız" name="creditCartNumber" mask={cardMask} value={cardValue} onChange={handleCardChange} />
                                </Form.Group>
                                
                                <Form.Group as={Col} md="12" controlId="city" className="checkout-edit-input">
                                        <Field name="expirationMonth" component="select" value={selectMonth}
                                            onChange={handleMonthChange}
                                            >
                                            <option >Ay</option>
                                            <option value="01">01</option>
                                            <option value="02">02</option>
                                            <option value="03">03</option>
                                            <option value="04">04</option>
                                            <option value="05">05</option>
                                            <option value="06">06</option>
                                            <option value="07">07</option>
                                            <option value="08">08</option>
                                            <option value="09">09</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                           
                                               
                                        </Field>
                                </Form.Group>
                                <Form.Group as={Col} md="12" controlId="distirc" className="checkout-edit-input">
                                    <Field name="expirationYear" component="select" value={selectedDistrict}
                                            onChange={handleDistrictChange}
                                            > 
                                                <option>Yıl</option>
                                                {
                                                    kartDateTime()
                                                }
                                        </Field>
                                </Form.Group>
                                
                                <Form.Group as={Col} md="12" controlId="tcNo" className="checkout-edit-input">
                                    <InputMask pattern="[0-9]*" style={{padding:'10px'}} name="cvc" placeholder="Güvenlik Kodunu" mask="999"
                                        onChange={handleChange}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="12" controlId="isContractAccept" className="checkout-edit-input-addrescheckbox">
                                    <Form.Check type="checkbox" id="check-api-checkbox" name="isContractAccept">
                                            <Form.Check.Input type="checkbox" defaultChecked="true" isValid onChange={handleChange} />
                                            <Form.Check.Label className="sozlesme-label">
                                                 Ön Bilgilendirme Koşulları'nı ve 
                                                 <Button className="sozlesme-button" onClick={handleShow}>Mesafeli Satış Sözleşmesi'ni</Button> okudum, onaylıyorum
                                            </Form.Check.Label>
                                           </Form.Check>
                                </Form.Group>
                                    
                                    <Button variant="primary" type="submit" style={{float:'right'}}>
                                        Ödeme Yap
                                    </Button>
                                </Row> 
                         
                            </Form>
                        )}
                </Formik>
                <MesafeliSatis show={show} handleClose={handleContractClose}/>

                <Modal show={showModalShow} onHide={handleClose} className="payment-cart-modal">
                    <Modal.Header closeButton>
                    <Modal.Title>3D Güvenlik Bilgileri</Modal.Title>
                    </Modal.Header>
                    <Modal.Body id="bank-data-content">
                    {/* {bankContent !== undefined ? bankContent :'' } */}
                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal>
        </>
    );
}

export default DirectPayment;