import React from 'react';
import cartCargo from '../../../images/sepetkargo.webp'

function CartStaticArea() {
    return(
        <>

            <div className="video-area">
                <img src={cartCargo} alt="aynı gün kargo" />
                 <iframe width="100%" height="500" src="https://www.youtube.com/embed/8CK_0UqXS-E?controls=0&loop=1&rel=0&showinfo=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
        </>
    );
}

export default CartStaticArea;