import React, { Suspense,useState, useEffect,lazy} from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import { useHistory } from "react-router";
import './App.less';
import 'bootstrap/dist/css/bootstrap.css'
 import Header from './components/Header';
import {CategoryList, pageListRoute, slugify} from './constans';
import Footer from './components/Footer';
import Detail from './components/Detail';
import LoginOrSginUp from './components/LoginOrSginUp';
import Contact from './views/Contact';
import StaticPages from './views/StaticPages';
import User from './views/User';
import OrderInQuiry from './views/OrderInQuiry/inde';
import CartIndex from './components/Cart/CartIndex';
import OrderResult from './components/Cart/OrderResult';
import OrderResultError from './components/Cart/OrderResult/orderError';
import ResetPassword from './components/LoginOrSginUp/ForgotMyPassword/resetPassword';
import Recipes from './components/Layout/Recipes'; 
import LezzetlerDetail from './components/Layout/Recipes/detail';
import NotFound from './components/NotFound';
import CreateUserSuccess from './views/CreateUserSuccess';
import AboutAccount from './views/AboutAccount';
import ReactGA from 'react-ga4';
//import TagManager from 'react-gtm-module'
import DirectCheckout from './components/Cart/DirectCheckout';
import CustomPage from './views/CustomPage';
import loadingGif from './images/cart-loader.gif';
import { searchAllLezzetler } from './api/service/generalService';
import PastirmaStaticPage from './components/Category/pastirmaStaticPage';

function App() {
  const hist= useHistory();
  const [lezzetler, setLezzetler] = useState([]);
  
  const Home = lazy(() => import('./components/Layout/Home'));
  const Category = lazy(() => import('./components/Category'));
      const loadingImg = <div className="album-img">
      <img alt="loading" src={loadingGif} />
  </div>
  const Checkout = lazy(() => import('./components/Cart/Checkout'));

  const tagManagerArgs = {
    gtmId: 'AW-943416692',
    dataLayer: {
      userId: '001',
      userProject: 'Şahin724'
    }
  }
 
  useEffect(() => {
    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init('184192118716429') // facebookPixelId
        ReactPixel.pageView()
      })
        //ReactGA.initialize('UA-66923730-1');
        //ReactGA.ga('config', 'UA-66923730-1');

        
      ReactGA.initialize('G-6R4VNJYVZG'); 
      ReactGA.initialize('GTM-TNNVQV2'); 
        
        
        // TagManager.initialize(tagManagerArgs)
        if(lezzetler.length === 0) {
          getLezzetler()
        }
        // 
  }, [tagManagerArgs,lezzetler])


  async function getLezzetler() {
    const res = await searchAllLezzetler();
    if(!res.errStatus) {
        setLezzetler(res.data);
    }
    
  }
  
  return (
    <div className="App">
      <Router history={hist}>
        <Switch>
          <Suspense fallback={loadingImg}>
           <Route path="/" render={(props) => (props.location.pathname !== "/order/checkout" && props.location.pathname !== "/cart/index") && <Header categoryList={CategoryList}/> }></Route>
              <Route exact path="/home" component={Home} />
              <Route exact path="/" component={Home} />
              <Route exact path="/account/login" component={LoginOrSginUp} /> 
              <Route exact path="/sayfalar/iletisim" component={Contact} />
              {pageListRoute.map((page) => {
                  return(
                     <Route key={page.ID} exact path={"/sayfalar/"+slugify(page.sayfaBasligi)} component={StaticPages} />
                  );
              })}
              <Route exact path="/account/hesabim" component={User} />
              <Route exact path="/cart/index" component={CartIndex} />
              <Route exact path="/tarif/yeni-lezzetler" component={Recipes} /> 
              <Route exact path="/:categoryName" component={Category}/>
              <Route exact path="/:categoryName/:productName/:id" component={Detail} />
              <Route exact path="/sayfalar/siparis-sorgula" component={OrderInQuiry} />
              <Route exact path="/order/checkout" component={Checkout}/>
              <Route exact path="/order/siparis-verildi" component={OrderResult}/>
              <Route exact path="/order/siparis-alindi" component={OrderResult}/>
              <Route exact path="/order/resulterror" component={OrderResultError}/>
              {lezzetler.map((page) => {
                return(
                     <Route key={page.ID} exact path={"/tarif/yeni-lezzetler/"+slugify(page.TarifAdi)+'/:id'} component={LezzetlerDetail} />
                  );
              })}
              <Route exact path="/account/resetpassword" component={ResetPassword}/>
              <Route exact path="/account/successuser" component={CreateUserSuccess}/>
              <Route path='/sayfalar/sayfa-bulunamadi' exact component={NotFound}/>
              <Route path='/sayfalar/nedenuye-olmaliyim' exact component={AboutAccount} />
              <Route path='/order/payment' exact component={DirectCheckout} />
              <Route path='/sayfalar/fidan-bagisi' exact component={CustomPage} />
              <Route path='/sayfalar/kurumsal-satis' exact component={StaticPages} />
              <Route path='/sitemap.xml' exact component={PastirmaStaticPage} />
              <Route path='/pastirma/pastirma-cesitleri' exact component={PastirmaStaticPage} />
              <Route path="/" render={(props) => (props.location.pathname !== "/order/checkout") && <Footer categoryList={CategoryList}/> }></Route>
          </Suspense>
          </Switch>
      </Router>
    </div>
  );
}


export default App;
