import React, {useState,useRef,useEffect} from 'react';
import {searchByDistrict,searchCityList} from '../../../api/service/loginServices';
import { Button,Form,Row,Col} from 'react-bootstrap';
import { Formik ,Field} from 'formik';
import InputMask from 'react-input-mask';
import { slugify, CreateAccountAddressValidation } from '../../../constans';
import { createWithoutAccount } from '../../../api/service/cartServices';
import { getToken } from '../../../api/service/loginServices';
import Cookies from 'universal-cookie';

function CreateAccount(props) {
        const form = useRef(null);
        const cookies = new Cookies();
        const [coporateAddress, setCorporateAddress] = useState(false);
        const [cityList, setCityList] = useState([]);
        const [selectedCity, setSelectedCity] = useState();
        const [selectedDistrict, setSelectedDistrict] = useState();
        const [distirictList, setDistirictList] = useState([]);

        useEffect(() => {
            document.body.classList.add('uyeliksiz')
                if(cityList.length === 0) {
                    getCityList();        
                }
        });

    
        useEffect(() => {
            return () => {
                document.body.classList.remove('uyeliksiz');
            };
        }, []);

        const getCityList = async () => {
            const res = await searchCityList();
            if(!res.errStatus) {
                res.data = res.data.sort(function (a, b) {
                    return a.Sehir.localeCompare(b.Sehir); //using String.prototype.localCompare()
                });
                setCityList(res.data);
            }
        }


        const handleCityChange = async (e) => {
            setSelectedCity(e.target.value);
            const distiricts = await searchByDistrict(e.target.value);
            if(!distiricts.errStatus) {
                setDistirictList(distiricts.data);
            }
        }

        const handleDistrictChange = (e) => {
            setSelectedDistrict(e.target.value);
        }

        const handleSubmit = async (event) => {
            event.teslimatIl = parseInt(selectedCity);
            event.ilce = parseInt(selectedDistrict);
            event.cepTel = slugify(event.cepTel);
            event.cepTel = event.cepTel.replaceAll('-','');
            event.cepTel = event.cepTel.replaceAll(')','');
            event.cepTel = event.cepTel.trim();
            
            const data = {};
            data.teslimatAdresi = [event];
            data.ayniAdresMi = event.ayniAdresMi;
            data.siparisNotu = "";
            const res = await createWithoutAccount(data);
            if(!res.errStatus) {
                    var tokendata = "grant_type=password&username=" + res.data.data.email + "&password=" + res.data.data.sifre;
                    const result = await getToken(tokendata);
                    if(!result.errStatus) {
                        localStorage.setItem('access_token',result.data.access_token);
                        handleSetCookie(res.data.access_token, result.data.expires);  
                        props.handleOrderCreate(res);
                        props.handleSetPayment('odeme');
                    }
                
            }

            
        };

        const handleSetCookie = (token,expiresDate) => {
            cookies.set('access_token', token, { path: '/', expires: expiresDate });
        }

        const handleIndividualAddress = () => {
            setCorporateAddress(false);
        }
        const handleCorporateAddress = () => {
            if(!coporateAddress) {
                setCorporateAddress(true);
            }
            else {

                setCorporateAddress(false);
            }

        }

    return(
        <>
         
                <label className="form-title">01 Teslim alacak kişinin bilgileri</label>
                <Formik
                        onSubmit={handleSubmit}
                        initialValues={{
                            adSoyad:'',
                            adres: '',
                            adresBasligi:'',
                            cepTel:'',
                            teslimatIl: '',
                            ilce: '',
                            tcNo:  '',
                            ayniAdresMi:true,
                        }}
                        validationSchema={CreateAccountAddressValidation}
                        >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors,
                        }) => (
                        <Form ref={form} noValidate onSubmit={handleSubmit}>
                            <Row className="mb-12 address-edit-input-box">
                                <Form.Group as={Col} md="12" controlId="name" className="address-edit-input">
                                    <Form.Control
                                            type="text" placeholder="Ad Soyad"
                                            name="adSoyad"
                                            value={values.adSoyad}
                                            onChange={handleChange}
                                            isValid={touched.adSoyad && !errors.adSoyad}
                                            
                                            /> 
                                        {touched.adSoyad && errors.adSoyad ?
                                        <div type="invalid" className="validation-error">
                                            {errors.adSoyad}
                                        </div> : ''}
                            </Form.Group>                                
                            <Form.Group as={Col} md="12" controlId="phonenumber" className="address-edit-input ceptel">
                                <InputMask  pattern={window.screen.width < 478 ? "[0-9]*" :''} style={{padding:'10px'}} name="cepTel" placeholder="* Telefon numaranız" mask={window.screen.width < 478 ?"09999999999" :"0 (999) 999 99-99"}
                                            onChange={handleChange}
                                            value={values.cepTel}
                                            isValid={touched.cepTel && !errors.cepTel}
                                        />
                                          {touched.cepTel && errors.cepTel ?
                                                <div type="invalid" className="validation-error">
                                                    {errors.cepTel}
                                                </div> : ''}
                                </Form.Group>
                                <label className="form-title">02 Adres Bilgileriniz</label>
                                <Form.Group as={Col} md="12" controlId="adresBasligi" className="address-edit-input">
                                <Form.Control
                                        type="text" placeholder="Örnek: Evim, İşyerim vb"
                                        name="adresBasligi"
                                        value={values.adresBasligi}
                                        onChange={handleChange}
                                        isValid={touched.adresBasligi && !errors.adresBasligi}
                                        /> 
                                            {touched.adresBasligi && errors.adresBasligi ?
                                                    <div type="invalid" className="validation-error">
                                                        {errors.adresBasligi}
                                                    </div> : ''}
                                </Form.Group>
                                <Form.Group as={Col} md="12" controlId="city" className="address-edit-input">
                                        <Field name="teslimatIl" component="select" value={selectedCity}
                                            onChange={handleCityChange}
                                            >
                                            <option >İl Seçini</option>
                                                {
                                                cityList.map((city) => {
                                                        return(<option key={city.ID} value={city.ID}>{city.Sehir}</option>);

                                                    })
                                                }
                                        </Field>
                                </Form.Group>
                                <Form.Group as={Col} md="12" controlId="distirc" className="address-edit-input">
                                    <Field name="ilce" component="select" value={selectedDistrict}
                                            onChange={handleDistrictChange}
                                            >
                                            <option>İlçe Seçiniz</option>
                                            {
                                                distirictList.length > 0 ?
                                                    distirictList.map((distirict) => {
                                                        return(<option  key={distirict.Id} value={distirict.Id}>{distirict.Ilce}</option>);

                                                }) : ""
                                            }
                                        </Field>
                                </Form.Group>    
                                <Form.Group as={Col} md="12" controlId="tcNo" className="address-edit-input">
                                    <InputMask  pattern="[0-9]*" style={{padding:'10px'}} name="tcNo" placeholder="TC kimlik numaranızı giriniz" mask="99999999999"
                                        onChange={handleChange}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="12" controlId="adres" className="address-edit-input">
                                        <Form.Control
                                            as="textarea"
                                            rows="2"
                                            placeholder="Adres giriniz"
                                            name="adres"
                                            value={values.adres}
                                            onChange={handleChange}
                                            isValid={touched.adres && !errors.adres}
                                            style={{ height: '44px' }}
                                            /> 
                                               {touched.adres && errors.adres ?
                                                <div type="invalid" className="validation-error">
                                                    {errors.adres}
                                                </div> : ''}
                                </Form.Group>
                                <Form.Group as={Col} md="12" controlId="adres2" className="address-edit-input-addrescheckbox">
                                    <Form.Check type="checkbox" id="check-api-checkbo2" name="oneAddress">
                                            <Form.Check.Input name="ayniAdresMi" type="checkbox" defaultChecked="true" isValid onChange={handleChange} />
                                            <div className="checkbox-desc">
                                                <Form.Check.Label>Teslimat Adresini Fatura Adresi Olarak Kullan.</Form.Check.Label>
                                                <label>Fatura adresiniz teslimat adresinizden farklı ise fatura bilgilerinizi girmek için aşağıdaki
                                                </label>
                                                <label>“Yeni Fatura Adresi Ekle” butonunu tıklayarak Fatura Bilgilerinizi kayıt ediniz.</label>
                                            
                                            </div>
                                            </Form.Check>
                                </Form.Group>
                                <label className="form-title">03 Fatura Tipi</label>
                                    <Form.Group as={Col} md="12" controlId="adres" className="address-edit-input-checkbox">
                                        <Form.Check type="checkbox" id="check-api-bireysel" name="adrestip" >
                                                <Form.Check.Input type="radio"  name="adrestip" isValid onChange={handleIndividualAddress} />
                                                <Form.Check.Label htmlFor="check-api-bireysel">Bireysel</Form.Check.Label>
                                            </Form.Check>
                                            <Form.Check type="checkbox" id="check-api-kurumsal" name="adrestip" >
                                                <Form.Check.Input type="radio" name="adrestip" isValid  onChange={handleCorporateAddress}/>
                                                <Form.Check.Label htmlFor="check-api-kurumsal">Kurumsal</Form.Check.Label>
                                            </Form.Check>
                                    </Form.Group>
                                    
                                            <div style={{display:`${coporateAddress ? 'block' : 'none'}`}}>
                                            <Form.Group as={Col} md="12" controlId="adresName" className="address-edit-input">
                                            <Form.Control
                                                    type="text" placeholder="Vergi No"
                                                    name="vergiNo"
                                                    value={values.vergiNo}
                                                    onChange={handleChange}
                                                    isValid={touched.vergiNo && !errors.vergiNo}
                                                /> 
                                            </Form.Group>
                                            <Form.Group as={Col} md="12" controlId="adresName" className="address-edit-input">
                                            <Form.Control
                                                    type="text" placeholder="Vergi Dairesi"
                                                    name="vergiDaire"
                                                    value={values.vergiDaire}
                                                    onChange={handleChange}
                                                    isValid={touched.vergiDaire && !errors.vergiDaire}
                                                /> 
                                            </Form.Group>
                                        </div> 
                                
                                
                                </Row> 
                            
                                <Button variant="primary" type="submit" style={{float:'right'}}>
                                    Kaydet
                                </Button>
                            </Form>
                        )}
                        </Formik>
               
            
                    
                
               
        </>
    );
}

export default CreateAccount;