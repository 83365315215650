import React, { useEffect } from 'react';
import CreateAccount from './createAccout';
import {Button } from 'react-bootstrap'
const WithoutAccount = (props) => {

    
    useEffect(() => {
        document.body.classList.add('uyeliksiz');

    });

    useEffect(() => {
        return () => {
        document.body.classList.remove('uyeliksiz');
        };
    }, []);

    const handleSetPayment = (e) => {
        props.handleSetPayment(e)
    }

    return(
        <>
            <CreateAccount handleOrderCreate={props.handleOrderCreate} handleSetPayment={handleSetPayment} />
            <div className='checkout-payment-uyeliksiz'>
                <Button onClick={props.handleOrderCreate}>Ödemeyi Tamamla</Button>
            </div>
        </>
    );
}

export default WithoutAccount;