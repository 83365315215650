import React , {useState} from 'react';
import {searhCargo} from '../../api/service/generalService';
import {Accordion} from 'react-bootstrap';
import {formatDate, slugify} from '../../constans'
import { connect } from "react-redux";
import { Button,Modal } from 'react-bootstrap';
import sepet from '../../images/Sepetim.svg'
import { PropTypes } from 'prop-types';
import {
    adjustItemQty
  } from '../../action/productAction'
import AddToCartModal from '../../components/ProductCart/addToCartModal';
import  Comments  from '../User/Comments'
var HtmlToReactParser = require('html-to-react').Parser;

function OrderSearchList(props) {
    var htmlToReactParser = new HtmlToReactParser();
    const [addtoCartModal,setAddtoCartModal] = useState({
        show:false,
    });
    const [orderProductList , setOrderProductList] = useState([]);
    const [show, setShow] = useState();
    const [cargoInfo,setCargoInfo]= useState({
        message:"",
        status:false,
        show:false,
        data: null
    });

    const handleClose = () => setAddtoCartModal({show:false,siparisKalemleri:[]});
    const handleCommentModalClose = () => setShow(false);
    const handleCargoClose = () =>{  
        setCargoInfo({show:false, data:null, message:''})
    }       
    

    const handleRepeatMyOder = (order) => {
        order.SiparisKalemleri.forEach(orderItem => {
            if(orderItem.urunFotograflari !== null) {
                props.adjustItemQty(orderItem.urunFotograflari.id, orderItem.birim === 'Kg' ? orderItem.adet * 4 : orderItem.adet)
            }
        });
        setAddtoCartModal({show:true,siparisKalemleri:order.SiparisKalemleri});
    }

    const handleSearchCargo = async (cargoNo) => {
        if(cargoNo !== null && cargoNo !== undefined) {
            const res = await searhCargo(cargoNo);
            if(!res.errStatus) {
                if(res.data.status !== undefined){
                    setCargoInfo({message: res.data.status,show:true});}
                else {
                    setCargoInfo(
                        {
                            message: res.data.cargoStatus[res.data.cargoStatus.length - 1].eventName, 
                            status: res.data.transfer,
                            data: res.data,
                            show: true
                        });
                    }
            }
        }
        else {
            setCargoInfo({message:'Siparişiniz hazırlanıyor. Henüz kargo oluşturulmadı.',show:true});
        }
        
    }

    const handleCreateComment = (orderProduct) => {
        setOrderProductList(orderProduct);
        setShow(true)
    }

    
    if(props.orderList !== null) {
        if(props.orderList.length === 0) {
            return(
                <>
                    <div className="no-favorite">
                        <img src={sepet} alt="favori icon" />
                        <span> Henüz bir sipariş vermediniz </span>
                    </div>
        
                </>
            );
        }
        return(
            <>
              <Accordion  className="order-acordion-container">
                {props.orderList.map((order,i) => {
                    return(
                            <Accordion.Item eventKey={i} key={i}>
                                <Accordion.Header>
                                    <div className="order-status-box">
                                        <div className="order-image-box">
                                            <ul>
                                                    {Array.isArray(order.SiparisKalemleri) ? order.SiparisKalemleri.map(orderItem => {
                                                            return(
                                                                <div key={orderItem.kalemId}>                                                        
                                                                    <li>
                                                                        {orderItem.urunFotograflari !== null ? orderItem.urunFotograflari.image !== undefined ? 
                                                                            <img src={"https://www.sahin724.com/products/"+orderItem.urunFotograflari?.image} alt={orderItem.urunAdi} />
                                                                        : '' : ''}  
                                                                    </li>
                                                                    
                                                                </div>
                                                            )}) :''}
                                               
                                                 {order.SiparisKalemleri.length > 5 ?
                                                <li>
                                                    <span>+</span><span>{order.SiparisKalemleri.length}</span>
                                                </li> : ''}
                                            </ul>
                                        </div>
                                        <div className="order-number">
                                                <span>Sipariş No</span>
                                                <span>{order.SiparisNo}</span>
                                        </div>                              
                                        <div className="order-date">
                                                <span>Sipariş Tarihi</span>
                                                <span>{formatDate(order.SiparisTarihi)}</span>
                                        </div>
                                        <div className="order-state">
                                                <span className={slugify(order.SiparisDurumu)}>{order.SiparisDurumu}</span>
                                                <span className="siparis-saati">{formatDate(order.SiparisTarihi)}</span>
                                        </div>
                                    </div>
                                    <div className="order-button-box">
                                        <Button variant="primary" onClick={(() => handleRepeatMyOder(order))}>
                                            Siparişi Tekrarla
                                        </Button>
                                        <Button variant="primary" disabled={order.KargoTakipNo !== '' ? false : true}  onClick={(() => handleSearchCargo(order.KargoTakipNo))}>
                                            Kargom Nerede?
                                        </Button>
                                        <Button variant="primary" onClick={() => handleCreateComment(order.SiparisKalemleri)}>
                                            Ürünlere Yorum Yap
                                        </Button>

                                    </div>
                            
                                </Accordion.Header>
                                <Accordion.Body>
                                    {order.SiparisKalemleri.map(siparis => {
                                                return(
                                                    <div className="order-product-box" key={siparis.siparislerId+siparis.kalemId}>
                                                        <div className="order-product-image">
                                                        {siparis.urunFotograflari !== null ? siparis.urunFotograflari.image !== undefined ? 
                                                                        <img src={"https://www.sahin724.com/products/"+siparis.urunFotograflari?.image} alt={siparis.urunAdi} />
                                                                     : '' : ''} 
                                                        </div>
                                                        <div className="product-code">
                                                            <span>
                                                                Ürün Kodu {siparis.urunKodu}
                                                            </span>
                                                            <span>
                                                                {siparis.urunAdi}
                                                            </span>
                                                        </div>
                                                        { siparis.indirimYuzdesi !== null  && siparis.indirimYuzdesi !== 0 ?
                                                                <span className="indirimli-fiyat">
                                                                    {siparis.indirimYuzdesi} %
                                                                </span>
                                                                : ""
                                                        }
                                                    
                                                        <div className="oder-product-price">
                                                            <span>{siparis.toplamFiyat} TL</span>
                                                            <span>({siparis.birimFiyat} TL x {siparis.adet} {siparis.birim})</span>
                                                        </div>
                                                    </div>
                                                );
                                    })}
                                    <div className="order-address">
                                        <div className="adresler">
                                            <div className="teslimat-adresi">
                                                <h4>Teslimat Adresi</h4>
                                                {htmlToReactParser.parse(order.TeslimAdres)}
                                            </div>
                                            <div className="fatura-adresi">
                                                <h4>Fatura Adresi</h4>
                                                {htmlToReactParser.parse(order.FaturaAdres)}
                                            </div>


                                        </div>
                                        <div className="odeme-bilgileri">
                                            <h4>Ödeme Bilgileri</h4>
                                            <ul>
                                                <li><span>Sipariş Tutarı</span><span>{order.AraToplam} TL</span> </li>
                                               
                                                <li><span>Kargo Ücreti</span> <span>{(order.KargoUcret === 0 ? 
                                                        <span> <span className="kargo-indirim">40 TL</span> <span>Bedeva</span> </span> 
                                                    : order.KargoUcret + " TL")}</span></li>
                                                <li><span>Toplam</span> <span>{order.GenelToplam + order.KargoUcret} TL</span></li>
                                            </ul>
                                        </div>
                                    </div>
                
                                </Accordion.Body>
                            </Accordion.Item>
                )})}
                 <AddToCartModal
                    addtoCartModal={addtoCartModal.show}
                    handleClose={handleClose}
                    siparisKalemleri={addtoCartModal.siparisKalemleri}
                />
            </Accordion>
                <Modal show={cargoInfo.show} onHide={handleCargoClose}>
                    <Modal.Header closeButton>
                    <Modal.Title>{cargoInfo.message}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            cargoInfo.data !== null ? <>
                                <div className="cargo-info">
                                    <span>Alıcı: {cargoInfo.data.aliciAdi}</span>
                                    <span>
                                        <span>Kargo numaranız: {cargoInfo.data.kargoKodu}</span>
                                        <span>Çıkış şubesi: {cargoInfo.data.cikisSube}</span>
                                    </span>
                                </div>
                                <div className="cargo-hareketleri">
                                    <ul>
                                        {
                                            Array.isArray(cargoInfo.data.cargoStatus) ?
                                            cargoInfo.data.cargoStatus.map((status) => {
                                                return(
                                                    <li key={status.unitId}>
                                                        <span>Konumu: {status.unitName}</span>
                                                        <span>Durumu: {status.eventName}</span>
                                                    </li>
                                                );
                                            }) : 
                                            <li>
                                            <span>{cargoInfo.data.cargoStatus}</span>
                                        </li>
                                        }
                                    </ul>
                                </div>
                        </>: ''}
                    </Modal.Body>
                </Modal>
                <Modal show={show} onHide={handleCommentModalClose} centered >
                    <Modal.Header closeButton>
                    <Modal.Title>Yorum Yap</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Comments className="comments-popup" orderProductList={orderProductList}/>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
    
    return(
        <>
            <div className="no-favorite">
                <img src={sepet} alt="favori icon" />
                <span> Henüz bir sipariş vermediniz </span>
            </div>

        </>
    );
   
}
const mapDispatchToProps = dispatch => {
    return {
        adjustItemQty: (id,qty) => dispatch(adjustItemQty(id,qty))
    }
  }
  const mapStateToProps = (state) => ({
    cart: state.productList.cart,
   
  });

  OrderSearchList.propTypes = {
        adjustItemQty: PropTypes.func.isRequired
    };

  export default connect(mapStateToProps,mapDispatchToProps)(OrderSearchList);
