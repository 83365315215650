export const START_FETCH_PRODUCT_LIST= ' productListActions:startFetchingProductList';
export const FINISH_FETCH_PRODUCT_LIST = ' productListActions:finishFetchingProductList';
export const ERROR_FETCH_PRODUCT_LIST = ' productListActions:errorFetchingProductList';
export const LOAD_PRODUCT_LIST = ' productListActions:loadProductList';
export const ADD_TO_CART = "productListActions:ADD_TO_CART";
export const ADJUST_ITEM_QTY = "productListActions:ADJUST_ITEM_QTY";
export const REMOVE_FROM_CART = "productListActions:REMOVE_FROM_CART";
export const LOAD_CURRENT_ITEM = "productListActions:LOAD_CURRENT_ITEM";
export const CAMPAING_CHECK = "productListActions:CAMPAING_CHECK";
export const FREE_PRODUCT_ADD_TO_CART = "productListActions:FREE_PRODUCT_ADD_TO_CART";
export const REMOVE_CART_ITEM = "productListActions:REMOVE_CART_ITEM";
export const REMOVE_FREE_PRODUCT_CART_ITEM = "productListActions:REMOVE_FREE_PRODUCT_CART_ITEM";
export const ProductListFetchStates = {
  FETCHING: 'fetching',
  FETCHED: 'fetched',
  IDLE: 'idle',
  ERROR: 'error',
};

