import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { searchByIdLezzetler } from '../../../api/service/generalService';
import {Link} from 'react-router-dom'
import { slugify } from '../../../constans';
import ProductCart from '../../ProductCart';
import {categoryBaseUrl} from '../../../constans';
import { Swiper, SwiperSlide } from "swiper/react";
import loadingGif from '../../../images/cart-loader.gif';
import { Helmet } from 'react-helmet';

var HtmlToReactParser = require('html-to-react').Parser;


const LezzetlerDetail = (props) => {
    const [detail, setDetail] = useState(null)
    var htmlToReactParser = new HtmlToReactParser();

    useEffect(() => {
        getLezzetDetail(props.match.params?.id)
    },[props.match.params.id])

    useEffect(() => {
        document.body.classList.add('tarifler-detay')
    });


    useEffect(() => {
        return () => {
            document.body.classList.remove('tarifler-detay');
        };
    }, []);

    const getLezzetDetail = async (id) => {
        const res = await searchByIdLezzetler(id);
        if(!res.errStatus){
            setDetail(res.data[0]);
        }

    }

    if(detail !== null) {
        return(
            <>
                <Helmet>
                    <meta name="keywords" content={detail.AnahtarKelime}/>
                    <meta name="description" content={detail.TarifKisaAciklama} />
                    <meta name="og:title" property="og:title" content={detail.Baslik} />
                    <meta property="og:type" content={detail.Baslik} />
                    <meta property="og:description" content={detail.TarifKisaAciklama}/>
                    <meta property="og:url" content={(window.location.pathname + window.location.search)} />
                    <meta property="og:image" content={detail.ImageUrl} />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:site" content={(window.location.pathname + window.location.search)} />
                    <meta name="twitter:creator" content={(window.location.pathname + window.location.search)} />
                    <meta name="twitter:title"  content={detail.Baslik} />
                    <meta name="twitter:description" ontent={detail.TarifKisaAciklama}/>
                </Helmet>
                <Container fluid className="tarifler-detay-full-part">
                    <Container className="tarifler-detay-normal-part">
                        <h1>{detail.Baslik} Tarifi</h1>
                        <div className="breadcrumb">
                            <ol>
                                <li>
                                    <Link to="/">
                                        Anasayfa
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/tarif/yeni-lezzetler"}>
                                        Lezzetler
                                    </Link>
                                </li>
                                <li>
                                    <span>
                                        {detail.TarifAdi}
                                    </span>
                                </li>
                            </ol>
                        </div>
                        <div className="tarif-detay-grid-part">
                            {detail.Video !== null ?
                            <div className="tarif-video">
                                    {htmlToReactParser.parse(detail.Video)}
                            </div> :'' }
                            <div className="tarif-aciklama">
                                <h4>Hazırlanışı</h4>
                                {htmlToReactParser.parse(detail.TarifDetay)}
                            </div>
                        </div>
                        {detail.urunler.length > 0 ?
                        <div className="relation-product">
                                <h2>{detail.TarifAdi} İçin Önerilen Ürünler</h2>
                                <Swiper slidesPerView={window.screen.width < 600 ? 1.2 :  4.2} spaceBetween={20} navigation={true} freeMode={true} pagination={{
                                    "clickable": true
                                    }} className="mySwiper">
                                {
                                    detail.urunler.map(product =>
                                        <SwiperSlide key={product.ProductId}>
                                            <ProductCart  
                                                productName={product.ProductName ?? product.UrunAdi}
                                                link={product.CategoryProductUrl ?? '/'+ product.Category + '/' + (slugify(product.ProductName)+"/"+product.ProductId)}
                                                price={product.Price ?? product.UrunFiyat}
                                                key={product.ProductId ?? product.UrunId}
                                                priceDiscount={product.PriceDiscount ?? 0}
                                                id={product.ProductId ?? product.UrunId}
                                                unit={product.Unit ?? "Adet"}
                                                piece={product.Piece ?? 1}
                                                imageUrl={categoryBaseUrl+(product.ImageUrl ?? product.UrunImage) }/>
                                            </SwiperSlide>

                                            )
                                    }
                                </Swiper>
                          </div> : ''}

                    </Container>
                </Container>
            </> 
        );
    }
    return(
        <>
             <Container fluid className="tarifler-detay-full-part">
                <Container className="tarifler-detay-loading-part">
                    <img alt="lütfen bekleyiniz" src={loadingGif} />
                </Container>
            </Container>
            
        </>
    );
  
}

export default LezzetlerDetail;