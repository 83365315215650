import React, {  useState } from 'react'
import { connect } from "react-redux";
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import {
  addToCart,adjustItemQty,removeToCart
} from '../../action/productAction'
import { Dec, Inc } from '../../constans/svgExport';
import AddToCartModal from './addToCartModal';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function ProductCart(props) {

    const [addtoCartModal,setAddtoCartModal] = useState(false);
    const handleClose = () => setAddtoCartModal(false);
    const [increment,setIncrement] = useState(0);
    // const [decrement, setDecrement] = useState(0);

    const handleCloseAddtoCart = (unit,id,increment) => {
        if(increment !== 0) {
            if(unit === "Adet") props.adjustItemQty(id,increment);
            else  props.adjustItemQty(id,(increment/0.25));
        }
        else props.addToCart(id);
        setAddtoCartModal(true);
    }
    

    const handleIncrement = (unit) => {
        let unitInc = 0;
        if(increment === 0) {
            if(unit === 1)  unitInc = unit + 1;
            else  unitInc = unit + 0.25;
        }
        else {
            if(unit === 1)   unitInc = increment + 1;
            else  unitInc = increment + 0.25;
        }
        setIncrement(unitInc)
    }

    const handleDecrement = (piece,unit) => {
        let unitInc = 0;
        if((piece === 1 || piece === 0.25) && increment === 0) {
            if(piece === 1)  unitInc = piece ;
            else  unitInc = piece;
        }
        else if(unit === "Adet" && (increment === 0 || increment === 1)){  
            if(piece === 1)  unitInc = piece ;
            else  unitInc = piece;
        }
        else if(unit === "Kg" && (increment === 0.25 || increment === 0)){
            if(piece === 1)  unitInc = piece ;
            else  unitInc = piece;
        }
        else {
            if(piece === 1)   unitInc = increment - 1;
            else  unitInc = increment - 0.25;
        }
        setIncrement(unitInc)
    }

    return(
        <>
                          
            <div className="product-box" key={props.ProductId}>
                    <Link onClick={() => window.scrollTo(0, 0)} to={props.link}>
                        <div className="product-cart-image-box"  >
                        <LazyLoadImage
                            alt={props.ProductName}
                            src={props.imageUrl} // use normal <img> attributes as props
                            effect="blur"
                            />
                        </div>
                        </Link>
                        <div className="product-cart-desc">
                            <Link onClick={() => window.scrollTo(0, 0)} to={props.link}>
                                <h3>
                                        {props.productName}    
                                </h3>
                                <span className="product-count">
                                    <span>({props.piece} &nbsp;</span>
                                    <span> {props.unit})</span>
                                </span>
                                <div className="product-price">
                                    {props.priceDiscount !== 0 ? <p className="eski-fiyat">{props.unit === 'Kg' ? (props.priceDiscount / 4) : props.priceDiscount  } TL</p> : ''}
                                    {props.price !== null ? <p className="fiyat">{props.price} TL</p> : ''}
                                </div>
                            </Link>
                            <div className="product-add-to-cart">
                                    <div className="numbers-row">
                                        <Button className="dec"  onClick={() => handleDecrement(props.piece,props.unit)}>
                                                {Dec()}
                                        </Button>
                                        <span> {increment === 0 ? props.piece : increment} {props.unit}</span>
                                        <Button className="inc" onClick={() => handleIncrement(props.piece)}>
                                               {Inc()}
                                        </Button>
                                    </div>
                                <div className="add-to-cart">   
                                        <Button  onClick={() => handleCloseAddtoCart(props.unit,props.id,increment)}>
                                            Sepete Ekle
                                        </Button>
                                </div>
                            </div>                       
                        </div>
            </div>      

            <AddToCartModal
                addtoCartModal={addtoCartModal}
                handleClose={handleClose}
                productName={props.productName}
                imageUrl={props.imageUrl}
                piece={props.piece}
                unit={props.unit}
                priceDiscount={props.priceDiscount}
                price={props.price}
                increment={increment === 0 ? props.piece : increment}
            />
        </>
    );

}

  const mapDispatchToProps = dispatch => {
    return {
        addToCart: id => dispatch(addToCart(id)),
        removeToCart: id => dispatch(removeToCart(id)),
        adjustItemQty: (id,qty) => dispatch(adjustItemQty(id,qty))
    }
  }
  const mapStateToProps = (state) => ({
    cart: state.productList.cart,
   
  });

  ProductCart.propTypes = {
        addToCart: PropTypes.func.isRequired,
        removeToCart: PropTypes.func.isRequired,
        cart:PropTypes.array.isRequired,
        adjustItemQty: PropTypes.func.isRequired
    };

  export default connect(mapStateToProps,mapDispatchToProps)(ProductCart);