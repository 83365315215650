import React , {useState,useRef }from 'react';
import {createComment} from '../../../api/service/generalService';
import {  Form,Row,Button ,Alert,Col,FloatingLabel} from 'react-bootstrap';
import { Formik,Field } from 'formik';
import Rater from 'react-rater';

const Comments = (props) => {
    const [rater, setRater] = useState(0);
    const [selectProduct, setSelectProduct]= useState(0);
    const [alert,setAlert]= useState({
        message:"",
        status:false,
        show:false,
        data:[]
    });
    const form = useRef(null);

    const handleSubmit = async (event) => {       
        if(rater !== 0 && event.yorum && selectProduct) {
              event.urunId = parseInt(selectProduct);
              event.yorumPuan = rater;
              event.kalitePuan = 0;
              event.urunPuan = rater;
              event.kargolamaPuan = 0;
              const res = await createComment(event);
              if(!res.errStatus) {
                 setAlert({variant:res.color,message:res.data.mesaj,show:true})
              }
        }
      };
      const handleChangeProduct = (e) => {
            setSelectProduct(e.target.value);
      }
   
    return(
        <>
            <Alert variant={alert.variant} show={alert.show}  onClose={() => setAlert({variant:'danger',message:'',show:false})} dismissible>
                {alert.message}
            </Alert>
            <Formik
        onSubmit={handleSubmit}
        initialValues={{
            yorum: '',
            product: 0
        }}
        >
        {({
            handleSubmit,
            handleChange,
            values,
        }) => (
        <Form ref={form} noValidate onSubmit={handleSubmit}>
            <Row className="mb-12 comments-input-box">
            <Rater total={5} rating={rater}  onRate={({rating}) => setRater(rating)}/>
                 <Form.Group as={Col} md="12" controlId="sipariskalemleri" className="sipariskalemleri-edit-input">
                        <Field name="comments" component="select" value={selectProduct}
                            onChange={handleChangeProduct}
                            >
                            <option >Ürün Seçiniz</option>
                            {props.orderProductList.length > 0 ?
                                props.orderProductList.map((product) => {
                                   if(product.urunBilgileri !== undefined){
                                        return(
                                            <option key={product.urunBilgileri.id} value={product.urunBilgileri.id}>{product.urunAdi}</option>

                                        );
                                   }
                                   else {
                                       if(product.urunFotograflari !== null){
                                            return(
                                                <option key={product.urunFotograflari.id} value={product.urunFotograflari.id}>{product.urunAdi}</option>
        
                                            );
                                       }
                                       else{
                                           return(<> </>);
                                       }
                                   }
                                    
                                })
                            :''}
                        </Field>
                </Form.Group>
                <FloatingLabel controlId="floatingTextarea" label="Comments" className="mb-3">
                </FloatingLabel>
                <FloatingLabel controlId="floatingTextarea2" label="Yorumunuz">
                    <Form.Control
                        type="textarea" placeholder="Yorumunuz"
                        name="yorum"
                        value={values.yorum}
                        onChange={handleChange}
                        as="textarea"
                        rows="2"
                        style={{ height: '100px' }}
                    />
                </FloatingLabel>
                
            </Row>
        
            <Button variant="primary" type="submit">
                Yorum Yap
            </Button>
            </Form>
        )}
        </Formik>
        </>
  
    );
}
export default Comments;