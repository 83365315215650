import React , {useEffect, useState} from 'react';
import { Modal,Table } from 'react-bootstrap';

const MesafeliSatis = (props) => {
    
    const [faturaAddress, setFaturaAddress] = useState({});
    const [teslimatAddress, setTeslimatAddress] = useState({});
    const [urunler, setUrunler] = useState([]);

    useEffect(() => {
        setFaturaAddress(JSON.parse(localStorage.getItem('faturaAdresi')));
        setTeslimatAddress(JSON.parse(localStorage.getItem('teslimatAdresi')));
        setUrunler((JSON.parse(localStorage.getItem('state'))))
    },[])

    const handleClose = () => {
        props.handleClose()
    }

    if(teslimatAddress !== null && faturaAddress !== null){
        return(
            <>
    
                <Modal show={props.show} onHide={handleClose}>
                    <Modal.Header closeButton>
                    <Modal.Title>Mesafeli Satış Sözleşmesi</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h4>TARAFLAR </h4>
                        <ul>
                            <li>
                                 Satıcı Bilgileri (Sözleşme’de “Satıcı” olarak anılacaktır.)
                                 <p>
                                   <p> 724 ŞAHİN DAĞITIM PAZARLAMA GIDA SAN. TİC. LTD. ŞTİ. </p>
    
                                    <p>Adres: Oruç Reis Mah. 3416 Sok. No:9 Kocasinan- Kayseri </p>
    
                                    <p>Ticaret Sicil Numarası: 44651</p>
    
                                    <p>Mersis numarası: 0001096757300019 </p>
    
                                    <p>Telefon: +90.533 153 31 93</p>
    
                                    <p>Faks: +90.352 351 0006</p>
    
                                    <p>Web Adresi: http://www.sahin724.com/</p>
    
                                    <p>E-Posta: info@sahin724.com</p>
                                </p>
                            </li>
                            <li>
                                Alıcı Bilgileri (Sözleşme’de “Alıcı” olarak anılacaktır.)
                                <p>{teslimatAddress.AdSoyad}</p>
                            <p> {teslimatAddress.Adres +" "+ teslimatAddress.Iller} </p>
                            <p>{teslimatAddress.CepTel}</p>
                            </li>
                        </ul>
                        <h4>KONU </h4>
                        <p>İşbu sözleşmenin konusu, Alıcı’nın http://www.sahin724.com internet sitesinden elektronik ortamda sipariş verdiği ve Satıcı’dan satın aldığı, sözleşmede belirtilen nitelikleri haiz, satış ve teslimat fiyatı aşağıda belirtilen ürünün satışı ve teslimi ile ilgili olarak 6502 sayılı Tüketicinin Korunması Hakkında Kanun ve Mesafeli Sözleşmeler Yönetmeliği (beraber “Mevzuat” olarak anılacaktır) hükümleri uyarınca tarafların hak ve yükümlülüklerinin saptanmasıdır.</p>
                        <h4>SÖZLEŞME KONUSU ÜRÜN, SATIŞ BEDELİ VE TESLİMAT </h4>
                        <p>Sözleşme konusu ürünün türü, miktarı ve vergiler dâhil satış bedeli ve teslimat bilgileri aşağıda yer almaktadır.</p>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                <th>Ürün Adı</th>
                                <th>Adet</th>
                                <th>Kdv Hariç Birim Fiyatı</th>
                                <th>Kdv Oranı</th>
                                <th>Kdv Dahil Toplam Tutar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {urunler ? 
                                    urunler.map((urun) => {
                                        return(
                                            <tr key={urun.ProductId}>
                                                <td>{urun.ProductName}</td>
                                                <td>{urun.qty*urun.Piece}</td>
                                                <td>{urun.Unit === 'Kg' ? 4*urun.Price : urun.Price}</td>
                                                <td>8%</td>
                                                <td>{((urun.qty*urun.Price*8)/100) + urun.qty*urun.Price}</td>
                                            </tr>
                                        );
                                    }) : ''
                                }
                            </tbody>
                            </Table>
                            <p>Ödeme Şekli ve Planı: Kredi Kartı</p>
                            <p>Vade farkı hesabında kullanılan faiz oranı : % 0</p>
                            <p>Teslimat masrafları : Kargo ücreti 40 TL 'dir. Ücret ödeme işlemi sırasında toplam alışveriş bedeli içerisine dahil edilmiş olarak alıcıdan tahsil edilecektir.</p>
                            <p>Yukarıda belirtilen satış bedeli Alıcı’dan tahsil edilecek olup Alıcı, ödemeyi kredi kart veya EFT/Havale aracılığıyla veya ürünün teslimi sırasında nakit olarak yapabilir. Ancak satış bedelinin 400 Türk Lirası’ndan fazla olduğu durumlarda nakit satış yapılması mümkün değildir.
                            Teslimat masrafları toplam sipariş tutarına göre Alıcı veya Satıcı’ya aittir. Kargo bedelinin Alıcı’ya ait olduğu durumlarda kargo bedeli yukarıda Teslimat Masraflarında yer almaktadır.</p>
    
                            <h4>Teslimat Bilgileri </h4>
                            <p>{teslimatAddress.AdSoyad}</p>
                            <p> {teslimatAddress.Adres +" "+ teslimatAddress.Iller} </p>
                            <p>{teslimatAddress.CepTel}</p>
                            <h4>Fatura Bilgileri </h4>
                            <p>{faturaAddress.AdSoyad}</p>
                            <p> {faturaAddress.Adres +" "+ faturaAddress.Iller} </p>
                            <p>{faturaAddress.CepTel}</p>
                            <p>Belirtilen sürede teslim edilemeyen ürünlerin teslimatı, Mevzuata uygun olarak siparişin iletildiği günden itibaren azami 30 güne kadar uzatılabilecektir.</p>
                            <h4>Teslim Usulü</h4>
                            <p>Ürün teslimi, Alıcı’ya veya işbu sözleşmede Alıcı tarafından belirlenmiş teslim almaya yetkili kişi veya kişilerden başkasına yapılmayacaktır. Teslimat esnasında, teslim almaya yetkili kişilerin kimlik ibrazı zorunludur. Bu kişiler, kimlik ibraz etmedikleri takdirde kendilerine teslimat yapılmaktan imtina edilecektir</p>
                            <p>Sözleşme konusu ürün, Alıcı’dan başka bir kişi veya kişilere teslim edilecek ise, teslim edilecek kişi veya kişilerin teslimatı kabul etmemesinden Satıcı sorumlu tutulamaz.</p>
                            <h4>ALICININ TAAHHÜTLERİ</h4>
                            <p>Alıcı www.sahin724.com internet sitesinde sözleşme konusu ürünün temel nitelikleri, satış fiyatı ve ödeme şekli ile teslimata ilişkin ön bilgileri okuyup bilgi sahibi olduğunu ve elektronik ortamda gerekli teyidi verdiğini beyan eder.</p>
                            <p>Alıcı bu sözleşmeyi elektronik ortamda teyit etmekle, mesafeli sözleşmelerin akdinden önce, Satıcı tarafından tüketiciye verilmesi gereken adres, telefon numarası, siparişi verilen ürünlere ait temel özellikler, ürünlerin vergiler dâhil fiyatı, ödeme ve teslimat bilgilerini ve cayma hakkına ilişkin doğru ve eksiksiz olarak edindiğini teyit etmiş olur.</p>
                            <p>Alıcı, sözleşme konusu ürünü teslim almadan önce muayene edecek; ezik, kırık, ambalajı yırtılmış vb. hasarlı ve ayıplı ürün kargo şirketinden teslim almayacaktır. Alıcı, ürünün kargo şirketinden teslim alınması sırasında fark edilemeyecek olan hasar veya ayıpları telim sonrası fark ederse Satıcı’yı durumdan info@sahin724.com e-posta adresi üzerinden derhal haberdar edecektir.</p>
                            <p>Ürünün tesliminden sonra Alıcı’ya ait kartın Alıcı’nın kusurundan kaynaklanmayan bir şekilde yetkisiz kişilerce haksız veya hukuka aykırı olarak kullanılması nedeni ile ilgili banka veya finans kuruluşunun ürün bedelini Satıcı’ya ödememesi halinde, Alıcı, borcun gecikmeli ifasından dolayı Satıcı’nın oluşan zarar ve ziyanını ödemeyi kabul eder</p>
                            <p>Alıcı, işbu sözleşme çerçevesinde verdiği kişisel bilgilerin doğru olduğunu beyan ve taahhüt eder. Kişisel bilgilerin doğruluğundan Alıcı sorumludur. Satıcı’nın bu bilgilerin doğru olmaması nedeniyle uğrayacağı tüm zararlardan Alıcı sorumludur.</p>
                            <h4>SATICININ TAAHHÜTLERİ </h4>
                            <p>Satıcı, sözleşme konusu ürünün sağlam, eksiksiz, siparişte belirtilen niteliklere uygun teslim etmeyi taahhüt eder.</p>
                            <p>Satıcı, sözleşme konusu ürünün tesliminin imkânsızlaşması halinde sözleşme konusu yükümlülüklerini yerine getiremezse, bu durumu, Alıcı’ya bildirir ve takdiri Satıcı’ya ait olmak üzere eşit kalite ve fiyatta farklı bir ürün tedarik edebilir veya ürün bedelini iade edebilir.</p>
                            <h4>CAYMA HAKKININ OLMAYIŞI </h4>
                            <p>Satıcı ile tüketici sıfatını haiz Alıcı arasında işbu Mesafeli Satış Sözleşmesi’nin akdedilmesinden itibaren 14 gün içinde ve Mesafeli Sözleşmeler Yönetmeliği Madde 9 hükümleri uyarınca hiçbir hukuki ve cezai sorumluluk üstlenmeksizin, hiçbir gerekçe göstermeksizin ve cezai şart ödemeksizin tüketicinin hizmeti reddederek cayma hakkına sahip olması ile birlikte; Mesafeli Sözleşmeler Yönetmeliği Madde 15’in 1. fıkrasının (c) bendi uyarınca, çabuk bozulabilen veya son kullanma tarihi geçebilecek malların teslimine ilişkin sözleşmelerde, (ç) bendi uyarınca tesliminden sonra ambalaj, bant, mühür, paket gibi koruyucu unsurları açılmış olan mallardan; iadesi sağlık ve hijyen açısından uygun olmayanların teslimine ilişkin sözleşmelerde ve aynı maddenin (g) bendi uyarınca, belirli bir tarihte veya dönemde yapılması gereken, konaklama, eşya taşıma, araba kiralama, yiyecek-içecek tedariki ve eğlence veya dinlenme amacıyla yapılan boş zamanın değerlendirilmesine ilişkin sözleşmelerde tüketici cayma hakkını kullanamaz. Bu çerçevede Alıcı’nın cayma hakkı bulunmamaktadır.</p>
                            <h4>UYGULANACAK HUKUK VE YETKİLİ MAHKEME </h4>
                            <p>İşbu Sözleşme, Türk hukukuna tâbidir. İşbu sözleşmeden kaynaklanabilecek ihtilaflarda, Gümrük ve Ticaret Bakanlığınca ilân edilen değere kadar Tüketici Hakem Heyetleri, belirtilen değer üstüne Tüketici Mahkemeleri; Tüketici Mahkemelerinin bulunmadığı yerlerde Asliye Hukuk Mahkemeleri yetkilidir.</p>
                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal>
    
            </>
        );
    }
    else {
        return(
            <>
    
                <Modal show={props.show} onHide={handleClose}>
                    <Modal.Header closeButton>
                    <Modal.Title>Mesafeli Satış Sözleşmesi</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h4>TARAFLAR </h4>
                        <ul>
                            <li>
                                 Satıcı Bilgileri (Sözleşme’de “Satıcı” olarak anılacaktır.)
                                 <p>
                                   <p> 724 ŞAHİN DAĞITIM PAZARLAMA GIDA SAN. TİC. LTD. ŞTİ. </p>
    
                                    <p>Adres: Oruç Reis Mah. 3416 Sok. No:9 Kocasinan- Kayseri </p>
    
                                    <p>Ticaret Sicil Numarası: 44651</p>
    
                                    <p>Mersis numarası: 0001096757300019 </p>
    
                                    <p>Telefon: +90.533 153 31 93</p>
    
                                    <p>Faks: +90.352 351 0006</p>
    
                                    <p>Web Adresi: http://www.sahin724.com/</p>
    
                                    <p>E-Posta: info@sahin724.com</p>
                                </p>
                            </li>
                            <li>
                                Alıcı Bilgileri (Sözleşme’de “Alıcı” olarak anılacaktır.)
                          
                            </li>
                        </ul>
                        <h4>KONU </h4>
                        <p>İşbu sözleşmenin konusu, Alıcı’nın http://www.sahin724.com internet sitesinden elektronik ortamda sipariş verdiği ve Satıcı’dan satın aldığı, sözleşmede belirtilen nitelikleri haiz, satış ve teslimat fiyatı aşağıda belirtilen ürünün satışı ve teslimi ile ilgili olarak 6502 sayılı Tüketicinin Korunması Hakkında Kanun ve Mesafeli Sözleşmeler Yönetmeliği (beraber “Mevzuat” olarak anılacaktır) hükümleri uyarınca tarafların hak ve yükümlülüklerinin saptanmasıdır.</p>
                        <h4>SÖZLEŞME KONUSU ÜRÜN, SATIŞ BEDELİ VE TESLİMAT </h4>
                        <p>Sözleşme konusu ürünün türü, miktarı ve vergiler dâhil satış bedeli ve teslimat bilgileri aşağıda yer almaktadır.</p>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                <th>Ürün Adı</th>
                                <th>Adet</th>
                                <th>Kdv Hariç Birim Fiyatı</th>
                                <th>Kdv Oranı</th>
                                <th>Kdv Dahil Toplam Tutar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {urunler ? 
                                    urunler.map((urun) => {
                                        return(
                                            <tr key={urun.ProductId}>
                                                <td>{urun.ProductName}</td>
                                                <td>{urun.qty*urun.Piece}</td>
                                                <td>{urun.Unit === 'Kg' ? 4*urun.Price : urun.Price}</td>
                                                <td>8%</td>
                                                <td>{((urun.qty*urun.Price*8)/100) + urun.qty*urun.Price}</td>
                                            </tr>
                                        );
                                    }) : ''
                                }
                            </tbody>
                            </Table>
                            <p>Ödeme Şekli ve Planı: Kredi Kartı</p>
                            <p>Vade farkı hesabında kullanılan faiz oranı : % 0</p>
                            <p>Teslimat masrafları : Kargo ücreti 40 TL 'dir. Ücret ödeme işlemi sırasında toplam alışveriş bedeli içerisine dahil edilmiş olarak alıcıdan tahsil edilecektir.</p>
                            <p>Yukarıda belirtilen satış bedeli Alıcı’dan tahsil edilecek olup Alıcı, ödemeyi kredi kart veya EFT/Havale aracılığıyla veya ürünün teslimi sırasında nakit olarak yapabilir. Ancak satış bedelinin 400 Türk Lirası’ndan fazla olduğu durumlarda nakit satış yapılması mümkün değildir.
                            Teslimat masrafları toplam sipariş tutarına göre Alıcı veya Satıcı’ya aittir. Kargo bedelinin Alıcı’ya ait olduğu durumlarda kargo bedeli yukarıda Teslimat Masraflarında yer almaktadır.</p>
    
                           
                            <p>Belirtilen sürede teslim edilemeyen ürünlerin teslimatı, Mevzuata uygun olarak siparişin iletildiği günden itibaren azami 30 güne kadar uzatılabilecektir.</p>
                            <h4>Teslim Usulü</h4>
                            <p>Ürün teslimi, Alıcı’ya veya işbu sözleşmede Alıcı tarafından belirlenmiş teslim almaya yetkili kişi veya kişilerden başkasına yapılmayacaktır. Teslimat esnasında, teslim almaya yetkili kişilerin kimlik ibrazı zorunludur. Bu kişiler, kimlik ibraz etmedikleri takdirde kendilerine teslimat yapılmaktan imtina edilecektir</p>
                            <p>Sözleşme konusu ürün, Alıcı’dan başka bir kişi veya kişilere teslim edilecek ise, teslim edilecek kişi veya kişilerin teslimatı kabul etmemesinden Satıcı sorumlu tutulamaz.</p>
                            <h4>ALICININ TAAHHÜTLERİ</h4>
                            <p>Alıcı www.sahin724.com internet sitesinde sözleşme konusu ürünün temel nitelikleri, satış fiyatı ve ödeme şekli ile teslimata ilişkin ön bilgileri okuyup bilgi sahibi olduğunu ve elektronik ortamda gerekli teyidi verdiğini beyan eder.</p>
                            <p>Alıcı bu sözleşmeyi elektronik ortamda teyit etmekle, mesafeli sözleşmelerin akdinden önce, Satıcı tarafından tüketiciye verilmesi gereken adres, telefon numarası, siparişi verilen ürünlere ait temel özellikler, ürünlerin vergiler dâhil fiyatı, ödeme ve teslimat bilgilerini ve cayma hakkına ilişkin doğru ve eksiksiz olarak edindiğini teyit etmiş olur.</p>
                            <p>Alıcı, sözleşme konusu ürünü teslim almadan önce muayene edecek; ezik, kırık, ambalajı yırtılmış vb. hasarlı ve ayıplı ürün kargo şirketinden teslim almayacaktır. Alıcı, ürünün kargo şirketinden teslim alınması sırasında fark edilemeyecek olan hasar veya ayıpları telim sonrası fark ederse Satıcı’yı durumdan info@sahin724.com e-posta adresi üzerinden derhal haberdar edecektir.</p>
                            <p>Ürünün tesliminden sonra Alıcı’ya ait kartın Alıcı’nın kusurundan kaynaklanmayan bir şekilde yetkisiz kişilerce haksız veya hukuka aykırı olarak kullanılması nedeni ile ilgili banka veya finans kuruluşunun ürün bedelini Satıcı’ya ödememesi halinde, Alıcı, borcun gecikmeli ifasından dolayı Satıcı’nın oluşan zarar ve ziyanını ödemeyi kabul eder</p>
                            <p>Alıcı, işbu sözleşme çerçevesinde verdiği kişisel bilgilerin doğru olduğunu beyan ve taahhüt eder. Kişisel bilgilerin doğruluğundan Alıcı sorumludur. Satıcı’nın bu bilgilerin doğru olmaması nedeniyle uğrayacağı tüm zararlardan Alıcı sorumludur.</p>
                            <h4>SATICININ TAAHHÜTLERİ </h4>
                            <p>Satıcı, sözleşme konusu ürünün sağlam, eksiksiz, siparişte belirtilen niteliklere uygun teslim etmeyi taahhüt eder.</p>
                            <p>Satıcı, sözleşme konusu ürünün tesliminin imkânsızlaşması halinde sözleşme konusu yükümlülüklerini yerine getiremezse, bu durumu, Alıcı’ya bildirir ve takdiri Satıcı’ya ait olmak üzere eşit kalite ve fiyatta farklı bir ürün tedarik edebilir veya ürün bedelini iade edebilir.</p>
                            <h4>CAYMA HAKKININ OLMAYIŞI </h4>
                            <p>Satıcı ile tüketici sıfatını haiz Alıcı arasında işbu Mesafeli Satış Sözleşmesi’nin akdedilmesinden itibaren 14 gün içinde ve Mesafeli Sözleşmeler Yönetmeliği Madde 9 hükümleri uyarınca hiçbir hukuki ve cezai sorumluluk üstlenmeksizin, hiçbir gerekçe göstermeksizin ve cezai şart ödemeksizin tüketicinin hizmeti reddederek cayma hakkına sahip olması ile birlikte; Mesafeli Sözleşmeler Yönetmeliği Madde 15’in 1. fıkrasının (c) bendi uyarınca, çabuk bozulabilen veya son kullanma tarihi geçebilecek malların teslimine ilişkin sözleşmelerde, (ç) bendi uyarınca tesliminden sonra ambalaj, bant, mühür, paket gibi koruyucu unsurları açılmış olan mallardan; iadesi sağlık ve hijyen açısından uygun olmayanların teslimine ilişkin sözleşmelerde ve aynı maddenin (g) bendi uyarınca, belirli bir tarihte veya dönemde yapılması gereken, konaklama, eşya taşıma, araba kiralama, yiyecek-içecek tedariki ve eğlence veya dinlenme amacıyla yapılan boş zamanın değerlendirilmesine ilişkin sözleşmelerde tüketici cayma hakkını kullanamaz. Bu çerçevede Alıcı’nın cayma hakkı bulunmamaktadır.</p>
                            <h4>UYGULANACAK HUKUK VE YETKİLİ MAHKEME </h4>
                            <p>İşbu Sözleşme, Türk hukukuna tâbidir. İşbu sözleşmeden kaynaklanabilecek ihtilaflarda, Gümrük ve Ticaret Bakanlığınca ilân edilen değere kadar Tüketici Hakem Heyetleri, belirtilen değer üstüne Tüketici Mahkemeleri; Tüketici Mahkemelerinin bulunmadığı yerlerde Asliye Hukuk Mahkemeleri yetkilidir.</p>
                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal>
    
            </>
        );
    }
 
}
export default MesafeliSatis;