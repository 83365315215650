import React , {useEffect} from 'react';
import { Container } from 'react-bootstrap';
import ReactGA from 'react-ga4';

const AboutAccount = () => {

    useEffect(() => {
        document.body.classList.add('about-account');

    });

    useEffect(() => {
        //ReactGA.pageview(window.location.pathname + window.location.search);
        ReactGA.send({ hitType: "pageview", page:window.location.href, title:"Hesap" });
        return () => {
        document.body.classList.remove('about-account');
        };
    }, []);

    return(
        <>
        <Container fluid>
            <Container style={{textAlign:'center',marginBottom:'20px'}}>
                <img width="100%" style={{borderRadius:'10px'}} src="https://www.sahin724.com/Images/nedenuyeolmaliyim.jpg" alt="üyelik hakkında" />
            </Container>
        </Container>
        </>
    );
}

export default AboutAccount