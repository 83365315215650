import React , {useEffect,useState} from 'react';
import { Accordion, Container} from 'react-bootstrap';
import { useHistory } from 'react-router';
import { searhByDirectOrder } from '../../../api/service/cartServices';
import DirectPayment from './directPayment';
//import DirectGarantiPay from './direcktGarantiPay';


const DirectCheckout = () => {
    const [order, setOrder] = useState([]);
    const history = useHistory();

    useEffect(() => {
        document.body.classList.add('direct-checkout');
    });

    useEffect(() => {
        const payUrl = window.location.href.split('?')[1];
        if(payUrl === undefined) {
            history.push('/sayfalar/sayfa-bulunamadi');
        }
        else {
            const getDirectCheckout = async (payUrl) =>  {
                const res = await searhByDirectOrder(payUrl);
                if(!res.errStatus){
                    if(!res.data.status) {
                        history.push('/sayfalar/sayfa-bulunamadi')
                    }
                    else {
                        setOrder(res.data);
                    }
                }
            }
            getDirectCheckout(payUrl);
        }
        return () => {
        document.body.classList.remove('direct-checkout');
        };
    }, [history]);

    

    if(order.siparisKalemleri !== undefined) {
        return(
            <>
            <Container fluid className="driect-checkout-full-part">
                <Container className="driect-checkout-normal-part">
                    <h1>Direkt Ödeme Yapabilirsiniz</h1>
                    <div className="direct-checkout-full-part">
                        <div className="direct-checkout-lef-part">
                            <Accordion defaultActiveKey="0"  >
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    <p>Tüm kredi kartları ile 3d güvenlik sistemiyle rahatlıkla ödeme yapabilirsiniz.</p>
                                    <h6>Kredi Kartı İle Ödeme</h6>
                                </Accordion.Header>
                                <Accordion.Body>
                                <DirectPayment order={order} />
                                </Accordion.Body>
                            </Accordion.Item>
                            {/* <Accordion.Item eventKey="1">
                                <Accordion.Header>
                                    <p>Garanti Pay ile kredi kartı bilginizi girmeden dijital olarak ödeme yapabilirsiniz.</p>
                                    <h6>Garanti Pay ile öde</h6>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <DirectGarantiPay />
                                </Accordion.Body>
                            </Accordion.Item> */}
                       
                            </Accordion>
                        </div>
                        <div className="cart-index-right-part">
                                            <h2>Sipariş Özeti</h2>
                                            <div className="order-product-price">
                                                <span>Ürünler</span>
                                                <div className="product-list">
                                                    {order.siparisKalemleri !== undefined  ? order.siparisKalemleri.map((product) => {
                                                        return(
                                                            <div className="product" key={product.productName}>
                                                                <p>
                                                                    <span>{product.productName}</span>
                                                                    <span>{product.adet + " " + product.birim}</span>
                                                                </p>
                                                                <span className="price">{product.price} TL</span>
                                                            </div>
                                                        );
                                                    }):''}
                                                </div>
                                              
                                            </div>
                                            <div className="total-price">
                                                    <span>Ara Toplam </span>
                                                    <span>{order.totalFiyat + order.genelIndirimler} TL</span>
                                                </div>
                                                {
                                                    order.genelIndirimler > 0 ? 
                                                        <div className="total-price indirimler">
                                                            <span>İndirim </span>
                                                            <span>{order.genelIndirimler} TL</span>
                                                        </div>
                                                : ''}
                                                
                                            <div className="order-shipping-price">
                                            
                                                <span>
                                                    <span>Kargo</span>
                                                    {order.cargoUcreti === 0 ? <span className="cargo-bedava">(Bedava)</span> : ''}
                                                    <span className="cargo-note">Yurtiçi Kargo Özel Soğuk Kargo Paketi</span>
                                                    <span className="cargo-mesaj">{order.kargoMesaj}</span>
                                                </span>
                                                <span className="cargo-price">{order.cargoUcreti} TL</span>
                                            </div>
                                            <div className="kdv-total-price">
                                                <span>Net Toplam (KDV dahil)</span>
                                                <span>{(order.totalFiyat + order.cargoUcreti).toFixed(2)} TL</span>
                                            </div>
                                        
                                            {window.screen.width < 500 ?
                                            <div className="fixed-area">
                                                <div className="kdv-total-price">
                                                    <span> Sepet toplam </span>
                                                    <span>{(order.totalFiyat + order.cargoUcreti).toFixed(2)} TL</span>
                                                </div>
                                            </div> : '' }
                                            
                                          
                        </div>
                        </div>
                 
                  
            
                </Container>
            </Container>
           
            </>
        );
    }
    else {
        return(
            <Container fluid className="driect-checkout-full-part">
                <Container className="driect-checkout-normal-part">
                </Container>
            </Container>
        )
    }
   
}

export default DirectCheckout;