import React from "react";


const TimerWarning = () => {
    const date = new Date();
    const between1 = new Date('2021-12-30');
    const between2 = new Date('2022-01-03');

    if (date >= between1 && date < between2) {
        return (
            <>
                <div className="yilbasi-tatili">
                    <p>
                        * Yılbaşı tatili nedeni ile siparişleriniz 3 Ocak Pazartesi günü kargoya verilecektir.
                    </p>
                </div>
            </>
        );
    }
    else {
        return(
            <>
            </>
        )
    }

}
export default TimerWarning;