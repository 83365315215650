import React, { useState } from 'react';
import { Accordion} from 'react-bootstrap';
import {  Col,ListGroup,Alert,Tabs,Tab} from 'react-bootstrap';
import { CargoSvg, ConfirmSvg, IadeSvg, OzellikSvg, StarSvg , comment} from '../../constans/svgExport';
import { ReactComponent as Logo } from '../../images/avantaj.svg';
import Rater from 'react-rater';
import { formatDate } from '../../constans';

var HtmlToReactParser = require('html-to-react').Parser;

function DetailMobileAcordion(props) {
    var htmlToReactParser = new HtmlToReactParser();
    const [alert, setAlert] = useState({
        show : false,
        message: '',
        variant:"danger",
    });

    if(window.screen.width < 600) {
        return(
            <>
                <Accordion  >
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                        <span>{ConfirmSvg("#E13F3F")}<span>Neden Şahin7/24 </span>  </span>
                        </Accordion.Header>
                        <Accordion.Body>
                        <Col md={12} className="neden-sahin">
                        <Col md={12} className="neden-sahin">
                            <Col md={6} className="video-part">
                            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/PI4Vxw4rxPQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </Col>
                            <Col md={6} className="aciklama-part">
                                <h2> Şahin 724 Lezzet Yolculuğu</h2>
                                <h4>Yorum ve Memnuniyet</h4>
                                <p>Teslim ettiğimiz 1 Milyon'dan fazla siparişte ürünlerin hazırlanmasından, teslimatına kadar tüm süreçte tek amacımız müşterilerimizin 100% memnuniyetini sağlamak.</p>
                                <p>Üretimden,paketlemeye,kargodan teslimata kadar tüm aşamaları profesyonel ekibimizle detaylı olarak takip ediyoruz.Fabrika çıkışlı en taze ürünleri özenle hazırlıyor, paketlemeleri vakumlu, özel soğuk muhafazalı en hassas şekilde zarar görmeden teslime hazır hale getiriyoruz. Şahin 724'den verdiğiniz her sipariş sizi şarşıtmaya odaklanarak hazırlanır. Her zaman incecik dilimlenmiş pastırmalarımız, geleneksel lezzetimiz sucuklarımız, geleneksel kavurma ve diğer tüm ürünlerimiz geleneksel bir lezzet şölenini kahvaltılarınıza, sofralarınıza taşıyoruz. Size ulaşan paketler bazen de sürpriz hediyeler ile sizi şaşırtır.</p>

                                <p>Şahin 724 Çıkabilecek en ufak sorunda tüm sorumluluğu alıyor, müşterimizin isteği üzerine para iadesi ya da yeniden gönderim gerçekleştirerek müşterilerimizi 100% memnun ediyoruz.</p>

                            </Col>
                        
                        </Col>
                            </Col>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>
                        <span>{OzellikSvg("#b60e0c")}<span>Ürün Özellikleri</span>  </span>
                        </Accordion.Header>
                        <Accordion.Body>
                        {htmlToReactParser.parse(props.Aciklama)} {htmlToReactParser.parse(props.Video)}
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>
                            <span>{IadeSvg("#2aaa61")}<span>Koşulsuz Şartsız İade </span>  </span>                    
                        </Accordion.Header>
                        <Accordion.Body>
                         {htmlToReactParser.parse(props.Aciklama2)}
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>
                            <span><Logo /><span>Şahin 7/24  Avantajları</span>  </span>
                        </Accordion.Header>
                        <Accordion.Body>
                        <p>Fabrikadan direkt kapınıza kadar güvenle ulaştırdığımız ürünlerimiz el değmeden hazırlanmaktadır. Sahin724.com ‘da sadece fabrika satış noktalarımızda satışa sunduğumuz sınırlı sayıda üretilen özel ürünlerimizi de bulabilirsiniz. Ürünlerimiz özel istekleriniz doğrultusunda dilimlenir ve istediğiniz gramajlarda paketlenebilmektedir. </p>
                        <p>Şahin 724 ‘den sipariş verdiğiniz her ürün size en güvenili şekilde ulaştırılır. Mevsim ne olursa olsun, dış ortam sıcaklığından etkilenmeden ürünlerimiz güvenle size ulaştırılır. </p>
                        <p> sahin724.com ‘dan alacağınız her ürün koşulsuz ve süresiz iade, iptal garantisi dahilindedir. Ürünlerin tarafınıza ulaşmasından sonra hiç bir neden belirtmeden iade, yenileme veya para iadesi için müşteri hizmetlerimiz ile iletişime geçebilirsiniz. </p>
                        <p> Şahin 724 alışverişlerinizde ödüllendirilir, sürpriz hediyeler alabilirsiniz. 100% müşteri memnuniyeti ilkemizle, 2015 yılından beri sektörünün en sevilen, güvenilir markası olarak milyonlarca eve sadece lezzet değil, şaşırtıcı ve keyifli anların yaşanmasına sebep olmaktan mutluluk duyuyoruz. Şahin 724 ‘ten beklediğiniz her sipariş bir mutluluktur. Burada rürprizler hiç eksik olmaz! </p>
                
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header>
                        <span> {CargoSvg('#6585b7')} <span>Kargonuz Nasıl Geliyor</span>  </span>
                        </Accordion.Header>
                        <Accordion.Body>
                        <p> Şahin Sucuklarından sipariş ettiğiniz her ürün, sipariş miktarına bakılmaksızın, özel koliler içinde muhafaza edilen straforların içinde size ulaşır. Strafor kutularımız içinde Türkiye’nin en gelişmiş gıda vakumlama cihazları ile vakumlanan ürün paketleri gazlı buz ve buz aküleri ile kargo yolculuğunu sürdürmektedir. </p>
                        <p> Paketlerimiz her mevsim ve hava koşullarında 96 saat yolculuk için uygun tasarlanmış ve üretilmiştir. Paketleme süreçleri için gerçekleştirdiğimiz ar-ge çalışmalarımız Türkiye’nin en güvenilir gıda ambalajlama teknolojisine sahip olmamızı sağlamıştır. </p>
                                   </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header>
                        <span>{StarSvg('#FEB53E')}<span>Yorum & Değerlendirme</span>  </span>
                        </Accordion.Header>
                        <Accordion.Body>
                        {alert.show ?  <Alert variant={alert.variant} onClose={() => setAlert({show:false})} dismissible>
                                {alert.message}
                            </Alert> : ''}
                            <div className="comment-grid-part">
                                <ListGroup>
                                {
                                    props.Yorum  ?
                                        props.Yorum.map((yorum,item) => {
                                            return(
                                            
                                                <ListGroup.Item key={item}>
                                                    <div className="comment-desc">
                                                        <Rater total={5} rating={yorum.UrunPuani} interactive={false}/>
                                                        <span>{yorum.Yorum}</span>
                                                    </div>
                                                
                                                    <div className="comment-date">
                                                        <span>Yorum sahibi : {yorum.AdSoyad}</span>
                                                        <span>Yorum tarihi : {formatDate(yorum.YorumTarihi)}</span>
    
                                                    </div>
                                                </ListGroup.Item>
                                            );
                                        }) : ''
                                }
                            </ListGroup>
                                {comment()}
                                <p>Henüz bir değerlendirme yapılmadı. Alışveriş yaptıktan sonra değerlendirme yapabilirsiniz.</p>
                            </div>
                                            
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
           
            </>
        );
    }
    else {
        return(
            <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example" className="mb-3 tab-container">
            <Tab eventKey="profile" title={<span>{OzellikSvg("#b60e0c")}<span>Ürün Özellikleri</span>  </span>} >
                {htmlToReactParser.parse(props.Video)} 
                {htmlToReactParser.parse(props.Aciklama)} 

            </Tab>
            <Tab eventKey="contact" title={<span>{IadeSvg("#2aaa61")}<span>Koşulsuz Şartsız İade </span>  </span>} >
               <div className="unconditionally"> 
                  <div> {htmlToReactParser.parse(props.Aciklama2)}</div>
                   <iframe width="100%" height="500" src="https://www.youtube.com/embed/8CK_0UqXS-E?controls=0&loop=1&rel=0&showinfo=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
               </div>
            </Tab>
            <Tab eventKey="home" title={<span>{ConfirmSvg("#E13F3F")}<span>Neden Şahin7/24 </span>  </span>}>
                <Col md={12} className="neden-sahin">
                    <Col md={6} className="video-part">
                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/PI4Vxw4rxPQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </Col>
                    <Col md={6} className="aciklama-part">
                        <h2> Şahin 724 Lezzet Yolculuğu</h2>
                        <h4>Yorum ve Memnuniyet</h4>
                        <p>Teslim ettiğimiz 1 Milyon'dan fazla siparişte ürünlerin hazırlanmasından, teslimatına kadar tüm süreçte tek amacımız müşterilerimizin 100% memnuniyetini sağlamak.</p>
                        <p>Üretimden,paketlemeye,kargodan teslimata kadar tüm aşamaları profesyonel ekibimizle detaylı olarak takip ediyoruz.Fabrika çıkışlı en taze ürünleri özenle hazırlıyor, paketlemeleri vakumlu, özel soğuk muhafazalı en hassas şekilde zarar görmeden teslime hazır hale getiriyoruz. Şahin 724'den verdiğiniz her sipariş sizi şarşıtmaya odaklanarak hazırlanır. Her zaman incecik dilimlenmiş pastırmalarımız, geleneksel lezzetimiz sucuklarımız, geleneksel kavurma ve diğer tüm ürünlerimiz geleneksel bir lezzet şölenini kahvaltılarınıza, sofralarınıza taşıyoruz. Size ulaşan paketler bazen de sürpriz hediyeler ile sizi şaşırtır.</p>

                        <p>Şahin 724 Çıkabilecek en ufak sorunda tüm sorumluluğu alıyor, müşterimizin isteği üzerine para iadesi ya da yeniden gönderim gerçekleştirerek müşterilerimizi 100% memnun ediyoruz.</p>

                    </Col>
                 
                </Col>
            </Tab>
            <Tab eventKey="contact2" title={<span><Logo /><span>Şahin 7/24  Avantajları</span>  </span>} >
                <p>Fabrikadan direkt kapınıza kadar güvenle ulaştırdığımız ürünlerimiz el değmeden hazırlanmaktadır. Sahin724.com ‘da sadece fabrika satış noktalarımızda satışa sunduğumuz sınırlı sayıda üretilen özel ürünlerimizi de bulabilirsiniz. Ürünlerimiz özel istekleriniz doğrultusunda dilimlenir ve istediğiniz gramajlarda paketlenebilmektedir. </p>
                <p>Şahin 724 ‘den sipariş verdiğiniz her ürün size en güvenili şekilde ulaştırılır. Mevsim ne olursa olsun, dış ortam sıcaklığından etkilenmeden ürünlerimiz güvenle size ulaştırılır. </p>
                <p> sahin724.com ‘dan alacağınız her ürün koşulsuz ve süresiz iade, iptal garantisi dahilindedir. Ürünlerin tarafınıza ulaşmasından sonra hiç bir neden belirtmeden iade, yenileme veya para iadesi için müşteri hizmetlerimiz ile iletişime geçebilirsiniz. </p>
                <p> Şahin 724 alışverişlerinizde ödüllendirilir, sürpriz hediyeler alabilirsiniz. 100% müşteri memnuniyeti ilkemizle, 2015 yılından beri sektörünün en sevilen, güvenilir markası olarak milyonlarca eve sadece lezzet değil, şaşırtıcı ve keyifli anların yaşanmasına sebep olmaktan mutluluk duyuyoruz. Şahin 724 ‘ten beklediğiniz her sipariş bir mutluluktur. Burada rürprizler hiç eksik olmaz! </p>
            </Tab>
            <Tab eventKey="contact3" title={<span> {CargoSvg('#6585b7')} <span>Kargonuz Nasıl Geliyor</span>  </span>} >
                <p> Şahin Sucuklarından sipariş ettiğiniz her ürün, sipariş miktarına bakılmaksızın, özel koliler içinde muhafaza edilen straforların içinde size ulaşır. Strafor kutularımız içinde Türkiye’nin en gelişmiş gıda vakumlama cihazları ile vakumlanan ürün paketleri gazlı buz ve buz aküleri ile kargo yolculuğunu sürdürmektedir. </p>
                <p> Paketlerimiz her mevsim ve hava koşullarında 96 saat yolculuk için uygun tasarlanmış ve üretilmiştir. Paketleme süreçleri için gerçekleştirdiğimiz ar-ge çalışmalarımız Türkiye’nin en güvenilir gıda ambalajlama teknolojisine sahip olmamızı sağlamıştır. </p>
            </Tab>
            <Tab eventKey="yorum" title={<span>{StarSvg('#FEB53E')}<span>Yorum & Değerlendirme</span>  </span>} >
                   {alert.show ?  <Alert variant={alert.variant} onClose={() => setAlert({show:false})} dismissible>
                        {alert.message}
                    </Alert> : ''}
                    <div className="comment-grid-part">
                        { 
                            props.Yorum.length ? <ListGroup>
                                {
                                    props.Yorum.map((yorum,item) => {
                                        return(
                                        
                                            <ListGroup.Item key={item}>
                                                <div className="comment-desc">
                                                    <Rater total={5} rating={yorum.UrunPuani} interactive={false}/>
                                                    <span>{yorum.Yorum}</span>
                                                </div>
                                            
                                                <div className="comment-date">
                                                    <span>Yorum sahibi : {yorum.AdSoyad}</span>
                                                    <span>Yorum tarihi : {formatDate(yorum.YorumTarihi)}</span>

                                                </div>
                                            </ListGroup.Item>
                                        );
                                    })
                                }  </ListGroup> : <> 
                                        {comment()}
                                        <p>Henüz bir değerlendirme yapılmadı. Alışveriş yaptıktan sonra değerlendirme yapabilirsiniz.</p> </>
                        } 
                    
                    </div>
            </Tab>
        </Tabs>
        );
    }

}

export default DetailMobileAcordion;