import React from 'react';

const Kvkk = () => {
    return(
        <>
                    <p><strong>KİŞİSEL VERİLERİN KORUNMASI POLİTİKASI</strong></p>
                    <h3><strong> </strong><strong>I. </strong><strong>VERİ GİZLİLİĞİ TAAHHÜDÜ</strong></h3>
                    <ul>
                        <li>İşbu Kişisel Verilerin Korunması Politikası (“<strong>Politika</strong>”), 724 Şahin Dağıtım Pazarlama Gıda Sanayi ve Ticaret Limited Şirketi (“<strong>Şirket</strong>”) 6698 Sayılı Kişisel Verilerin Korunması Kanunu başta olmak üzere ilgili mevzuat hükümleri uyarınca Kişisel Verileri korumaya yönelik yükümlülüklerini yerine getirirken ve Kişisel Verileri işlerken Şirket içerisinde ve/veya Şirket tarafından uyulması gereken esasları belirlemektedir.</li>
                    </ul>
                    <ul>
                        <li>Şirket, kendi bünyesinde bulunan Kişisel Veriler bakımından işbu Politikaya ve Politikaya bağlı olarak uygulanacak prosedürlere uygun davranmayı taahhüt eder.</li>
                    </ul>
                    <h3><strong>II. </strong><strong>POLİTİKANIN AMACI</strong></h3>
                    <p>İşbu Politikanın temel amacı, Şirket tarafından Kişisel Verilerin işlenmesine ve korunmasına yönelik yöntem ve süreçlere ilişkin esasları belirlemektir.</p>
                    <h3><strong>III. </strong><strong>POLİTİKANIN KAPSAMI</strong></h3>
                     
                    <ul>
                        <li>İşbu Politika, Şirketin işlemekte olduğu Kişisel Verilere yönelik tüm faaliyetleri kapsar ve söz konusu faaliyetlere uygulanır.</li>
                    </ul>
                    <ul>
                        <li>İşbu Politika, Kişisel Veri niteliği taşımayan verilere uygulanmaz.</li>
                    </ul>
                    <ul>
                        <li>İşbu Politika, KVK Düzenlemelerinin gerektirmesi halinde zaman zaman Ortaklar Kurulu onayı ile değiştirilebilir.</li>
                    </ul>
                     
                    <ul>
                        <li>KVK Düzenlemeleri ve işbu Politika arasında bir uyumsuzluk olması halinde KVK Düzenlemeleri esas alınır.</li>
                    </ul>
                     
                    <h3><strong>IV. </strong><strong>TANIMLAR</strong></h3>
                     
                    <ul>
                        <li>İşbu Politikada geçen tanımlar aşağıdaki anlamları ihtiva eder;</li>
                    </ul>
                     
                    <p><strong>“Açık Rıza”</strong> Belirli bir konuya ilişkin bilgilendirilmeye dayanan ve özgür iradeyle açıklanan rızayı ifade eder.</p>
                    <p><strong> </strong></p>
                    <p><strong>“Anonim Hale Getirme”</strong> Kişisel Verilerin, başka verilerle eşleştirilerek dahi hiçbir surette kimliği belirli veya belirlenebilir bir gerçek kişiyle ilişkilendirilemeyecek hâle getirilmesini ifade eder.</p>
                    <p><strong> </strong></p>
                    <p>“<strong>Aydınlatma Yükümlülüğü</strong>” Kişisel Verilerin elde edilmesi sırasında Veri Sorumlusu veya yetkilendirdiği kişinin, Veri Öznesine KVKK Madde 10 kapsamında bilgi vermesine ilişki yükümlülüğünü ifade eder.</p>
                     
                    <p><strong>“Kişisel Veri”</strong> Kimliği belirli veya belirlenebilir gerçek kişiye ilişkin her türlü bilgiyi ifade eder (işbu Politika kapsamında “Kişisel Veri” ifadesi uygun olduğu ölçüde aşağıda tanımlanan “Özel Nitelikli Kişisel Verileri de kapsayacaktır).</p>
                     
                    <p><strong>“Kişisel Veri İşleme”</strong> Kişisel Verilerin tamamen veya kısmen otomatik olan ya da herhangi bir veri kayıt sisteminin parçası olmak kaydıyla otomatik olmayan yollarla elde edilmesi, kaydedilmesi, depolanması, muhafaza edilmesi, değiştirilmesi, yeniden düzenlenmesi, açıklanması, aktarılması, devralınması, elde edilebilir hâle getirilmesi, sınıflandırılması ya da kullanılmasının engellenmesi gibi veri üzerinde gerçekleştirilen her türlü işlemi ifade eder.</p>
                     
                    <p><strong>“Kurul”</strong> Kişisel Verileri Koruma Kurulunu ifade eder.</p>
                     
                    <p>“<strong>Kurum</strong>” Kişisel Verileri Koruma Kurumunu ifade eder.</p>
                     
                    <p><strong>“KVKK” </strong>6698 sayılı Kişisel Verilerin Korunması Kanununu ifade eder.</p>
                     
                    <p><strong>“KVK Düzenlemeleri”</strong> 6698 sayılı Kişisel Verilerin Korunması Kanunu ile Kişisel Verilerin korunmasına yönelik ilgili diğer mevzuatı, düzenleyici ve denetleyici otoriteler, mahkemeler ve diğer resmi makamlar tarafından verilen, bağlayıcı kararları, ilke kararlarını, hükümleri, talimatları ile verilerin korunmasına yönelik uygulanabilir uluslararası anlaşmaları ve diğer her türlü mevzuatı ifade eder.</p>
                     
                    <p>“<strong>KVK Prosedürleri</strong>” Şirket’in ve çalışanların, işbu Politika kapsamında uyması gereken yükümlülükleri belirleyen prosedürleri ifade eder.</p>
                     
                    <p><strong>“Özel Nitelikli Kişisel Veri”</strong> Kişilerin ırkı, etnik kökeni, siyasi düşüncesi, felsefi inancı, dini, mezhebi veya diğer inançları, kılık ve kıyafeti, dernek, vakıf ya da sendika üyeliği, sağlığı, cinsel hayatı, ceza mahkûmiyeti ve güvenlik tedbirleriyle ilgili verileri ile biyometrik ve genetik verileri ifade eder.</p>
                    <p><strong> </strong></p>
                    <p><strong>“Silme veya Silinme”</strong> Kişisel Verilerin ilgili kullanıcılar için hiçbir şekilde erişilemez ve tekrar kullanılamaz hale getirilmesini ifade eder.</p>
                     
                    <p><strong>“Veri İşleyen” </strong>Veri Sorumlusu tarafından yetki alarak, Veri Sorumlusu adına Kişisel Verileri İşleyen gerçek veya tüzel kişiyi ifade eder.</p>
                     
                    <p><strong>“Veri Öznesi”</strong> Kişisel Verileri Şirket tarafından veya Şirket adına işlenen tüm gerçek kişileri ifade eder.</p>
                     
                    <p><strong>“Veri Sorumlusu” </strong>Kişisel Verilerin İşleme amaçları ve vasıtalarını belirleyen, veri kayıt sisteminin kurulmasından ve yönetilmesinden sorumlu bulunan gerçek veya tüzel kişiyi ifade eder.</p>
                     
                    <p>“<strong>Yok Etme</strong>” Kişisel Verilerin, hiç kimse tarafından hiçbir şekilde erişilemez, geri getirilemez ve tekrar kullanılamaz hale getirilmesi işlemini ifade eder.</p>
                     
                    <h3><strong>V. </strong><strong>KİŞİSEL VERİ İŞLEMENİN İLKELERİ</strong></h3>
                     
                    <ul>
                        <li><strong>Kişisel Verilerin Hukuka ve Dürüstlük Kurallarına Uygunluk Olarak İşlenmesi</strong></li>
                    </ul>
                    <p>                                               </p>
                    <p>Şirket, Kişisel Verileri, hukuka ve dürüstlük kurallarına uygun ve ölçülülük esasına dayalı olarak işler.</p>
                     
                    <ul>
                        <li><strong>Kişisel Verilerin Doğru ve Gerektiğinde Güncel Olması için Gerekli Önlemlerin Alınması</strong></li>
                    </ul>
                     
                    <p>Şirket, Kişisel Verilerin eksiksiz, doğru ve güncel olması için her türlü gerekli önlemleri alır ve Veri Öznesinin KVKK Düzenlemeleri kapsamında Kişisel Verilere yönelik değişiklik talep etmesi durumunda ilgili Kişisel Verileri günceller.</p>
                     
                    <ul>
                        <li><strong>Kişisel Verilerin Belirli, </strong><strong>Açık ve </strong><strong>Meşru Amaçlar Doğrultusunda İşlenmesi</strong></li>
                    </ul>
                    <p><strong> </strong></p>
                    <p>Kişisel Verilerin İşlenmesinden önce Şirket tarafından Kişisel Verilerin hangi amaçla işleneceği belirlenir. Bu kapsamda, Veri Öznesi KVK Düzenlemeleri kapsamında aydınlatılır ve gereken hallerde Açık Rızaları alınır.</p>
                     
                    <ul>
                        <li><strong>Kişisel Verilerin İşlendikleri Amaçla Bağlantılı, Sınırlı ve Ölçülü Olması</strong></li>
                    </ul>
                     
                    <p>Şirket, Kişisel Verileri yalnızca KVK Düzenlemeleri kapsamında istisnai hallerde (KVKK Madde 5.2 ve Madde 6.3) veya Veri Öznesinden alınan Açık Rıza kapsamındaki amaç doğrultusunda (KVKK Madde 5.1 ve Madde 6.2) ve ölçülülük esasına uygun olarak işler. Veri Sorumlusu, Kişisel Verileri belirlenen amaçların gerçekleştirilebilmesine elverişli bir biçimde işler ve amacın gerçekleştirilmesiyle ilgili olmayan veya ihtiyaç duyulmayan Kişisel Verileri işlemekten kaçınır.</p>
                     
                    <ul>
                        <li><strong>Kişisel Verilerin </strong><strong>İlgili Mevzuatta Öngörülen veya İşlendikleri Amaç için Gerekli Olan Süre</strong><strong> Kadar Muhafaza Edilmesi</strong></li>
                    </ul>
                    <p><strong> </strong></p>
                    <ul>
                        <li>Şirket, Kişisel Verileri amaca uygun olarak gerektiği kadar muhafaza eder. Şirketin, KVK Düzenlemelerinde öngörülen veya Kişisel Veri İşleme amacının gerektirdiği süreden daha uzun bir süreyle Kişisel Verileri muhafaza etmek istemesi halinde, Şirket KVK Düzenlemelerinde belirtilen yükümlülüklere uygun davranır.</li>
                        <li>Kişisel Veri İşleme amacının gerektirdiği süre sona erdikten sonra Kişisel Veriler Silinir veya Anonim Hale Getirilir. İşbu halde, Şirketin Kişisel Verileri aktardığı üçüncü kişilerin de Kişisel Verileri Silmesi, Yok Etmesi yahut Anonim Hale Getirmesi sağlanır.</li>
                        <li>Silme, Yok Etme ve Anonim Hale Getirme süreçlerinin işletilmesinden Şirket ve Şirket tarafından yetkilendirilen kişiler sorumludur. Bu kapsamda çalışmaların yapılması için Şirket gerekli teknik ve idari prosedürleri oluşturur.</li>
                    </ul>
                     
                    <h3><strong>VI. </strong><strong>KİŞİSEL VERİLERİN İŞLENMESİ</strong></h3>
                    <p><strong> </strong></p>
                    <p>Kişisel Veriler Şirket tarafından ancak aşağıda belirtilen usul ve esaslar kapsamında işlenebilir.</p>
                    <ul>
                        <li><strong>Açık Rıza</strong></li>
                    </ul>
                     
                    <ul>
                        <li>Kişisel Veriler, Veri Öznelerine Aydınlatma Yükümlülüğünün yerine getirilmesi çerçevesinde yapılacak bilgilendirme sonrası ve Veri Öznelerinin Açık Rıza vermesi halinde işlenir.</li>
                        <li>Veri Öznesinin Açık Rızası belirli bir konuya ilişkin, bilgilendirmeye dayalı olarak ve özgür iradeyle açıklanmalıdır.</li>
                        <li>Aydınlatma Yükümlülüğü çerçevesinde Veri Öznelerine Kişisel Verilerinin işleme, aktarım amaçları, alıcı grupları, Veri Sorumlusunun kimliği, Kişisel Verilerin toplama yöntemi, hukuki sebepleri ve Veri Öznelerinin hakları bildirilir.</li>
                        <li>Veri Öznesinin Açık Rızası, KVK Düzenlemelerine uygun yöntemlerle alınır. Açık Rızalar ispatlanabilir şekilde Şirket tarafından KVK Düzenlemeleri kapsamında gereken süre ile muhafaza edilir.</li>
                    </ul>

                    <ul>
                        <li><strong>Kişisel Verilerin Açık Rıza Alınmaksızın İşlenmesi</strong></li>
                    </ul>
                    <p><strong> </strong></p>
                    <ul>
                        <li>KVK Düzenlemeleri kapsamında Açık Rıza alınmaksızın Kişisel Verilerin İşlenmesinin öngörüldüğü durumlarda (KVKK Madde 5.2), Şirket Veri Öznesinin Açık Rızasını almaksızın Kişisel Verileri işleyebilir. Kişisel Verilerin bu şekilde işlenmesi durumunda Şirket KVK Düzenlemelerinin çizdiği sınırlar çerçevesinde Kişisel Verileri İşler. Bu kapsamda:</li>
                    </ul>
                    <p><strong> </strong></p>
                    <ul>
                        <li>Kanunlarda açıkça öngörülmesi diğer bir ifade ile kanunlarda Kişisel Verilerin işlenmesine ilişkin açıkça bir hüküm olması halinde Kişisel Veriler Şirket tarafından Açık Rıza olmaksızın işlenebilir.</li>
                        <li>Fiili imkânsızlık nedeniyle rızasını açıklayamayacak durumda bulunan veya rızasına hukuki geçerlilik tanınmayan Veri Öznesinin kendisinin ya da Veri Öznesi dışındaki bir başkasının hayatı veya beden bütünlüğünün korunması için zorunlu olması halinde Kişisel Veriler Şirket tarafından Açık Rıza olmaksızın işlenebilir.</li>
                        <li>Veri Öznesinin taraf olduğu bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, sözleşmenin taraflarına ait Kişisel Verilerin işlenmesinin gerekli olması halinde Kişisel Veriler Veri Öznelerinin Açık Rızaları olmadan Şirket tarafından işlenebilir.</li>
                        <li>Kişisel Verilerin İşlenmesi Şirketin hukuki yükümlülüğünü yerine getirmesi için zorunluysa, Kişisel Veriler Veri Öznelerinin Açık Rızaları olmadan Şirket tarafından işlenebilir.</li>
                        <li>Veri Öznesi tarafından herhangi bir şekilde kamuoyuna açıklanmış alenileştirilmiş olan Kişisel Veriler Açık Rıza alınmaksızın Şirket tarafından işlenebilir.</li>
                        <li>Kişisel Verilerin İşlenmesi bir hakkın tesisi, kullanılması veya korunması için zorunlu ise Kişisel Veriler Açık Rıza alınmaksızın Şirket tarafından işlenebilir.</li>
                        <li>Veri Öznesinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, Şirketin meşru menfaatleri için veri işlenmesinin zorunlu olması halinde Kişisel Veriler Şirket tarafından Açık Rıza olmaksızın işlenebilir.</li>
                    </ul>
                     
                    <h3><strong>VII. </strong><strong>ÖZEL NİTELİKLİ KİŞİSEL VERİLERİN İŞLENMESİ</strong></h3>
                    <p><strong> </strong></p>
                    <ul>
                        <li>Özel Nitelikli Kişisel Veriler yalnızca Veri Öznesinin Açık Rızasının bulunması yahut, cinsel hayat ve kişisel sağlık verileri dışındaki Özel Nitelikli Kişisel Veriler bakımından kanunlarda açıkça işlemenin zorunlu tutulması halinde işlenebilir.</li>
                    </ul>
                     
                    <ul>
                        <li>Sağlık ve cinsel hayata ilişkin Kişisel Veriler, ancak kamu sağlığının korunması, koruyucu hekimlik, tıbbî teşhis, tedavi ve bakım hizmetlerinin yürütülmesi, sağlık hizmetleri ile finansmanının planlanması ve yönetimi amacıyla, sır saklama yükümlülüğü altında bulunan kişiler (örn: Şirket hekimi) veya yetkili kurum ve kuruluşlar tarafından Açık Rıza almaksızın işlenebilir.</li>
                    </ul>
                     
                    <ul>
                        <li>Özel Nitelikli Kişisel Veriler İşlenirken, Kurul’un 31/01/2018 tarihli ve 2018/10 sayılı Kararı’nda yer alan önlemler başta olmak üzere Kurul tarafından belirlenen ve belirlenecek her türlü idari ve teknik önlemler alınır.</li>
                    </ul>
                     
                    <ul>
                        <li>Şirket, Özel Nitelikli Kişisel Verilerin işlenmesi süreçlerinde yer alan çalışanlara yönelik,</li>
                    </ul>
                     
                    <ul>
                        <li>KVK Düzenlemeleri ile Özel Nitelikli Kişisel Verilerin güvenliği konularında düzenli olarak eğitimler verecektir.</li>
                        <li>Gizlilik sözleşmeleri yapacaktır.</li>
                        <li>Özel Nitelikli Kişisel Verilere erişim yetkisine sahip kullanıcıların yetki kapsamlarını ve sürelerini net olarak tanımlayacaktır.</li>
                        <li>Periyodik olarak yetki kontrollerini gerçekleştirecektir.</li>
                        <li>Görev değişikliği olan ya da işten ayrılan çalışanların bu alandaki yetkilerini derhal kaldırır ve ilgili çalışana tahsis edilen envanteri derhal geri alacaktır.</li>
                    </ul>
                     
                    <ul>
                        <li>Özel Nitelikli Kişisel Verilerin elektronik ortamlara aktarılması durumunda, Özel Nitelikli Kişisel Verilerin işlendiği, muhafaza edildiği ve/veya erişildiği elektronik ortamlar ile ilgili olarak Şirket:</li>
                    </ul>
                     
                    <ul>
                        <li>Özel Nitelikli Kişisel Verileri kriptografik yöntemler kullanarak muhafaza edecektir.</li>
                        <li>Kriptografik anahtarları güvenli ve farklı ortamlarda tutacaktır.</li>
                        <li>Özel Nitelikli Kişisel Veriler üzerinde gerçekleştirilen tüm hareketlerin işlem kayıtlarını güvenli olarak loglayacaktır.</li>
                        <li>Özel Nitelikli Kişisel Verilerin bulunduğu ortamlara ait güvenlik güncellemelerini sürekli takip edecek, gerekli güvenlik testlerini düzenli olarak yapacak/yaptıracak, test sonuçlarını kayıt altına alacaktır.</li>
                        <li>Özel Nitelikli Kişisel Verilere bir yazılım aracılığıyla erişiliyorsa bu yazılıma ait kullanıcı yetkilendirmelerini yapacak, bu yazılımların güvenlik testlerini düzenli olarak yapacak/yaptıracak, test sonuçlarını kayıt altına alacaktır.</li>
                        <li>Özel Nitelikli Kişisel Verilere uzaktan erişim olması halinde en az iki kademeli kimlik doğrulama sistemi sağlayacaktır.</li>
                    </ul>
                     
                    <ul>
                        <li>Özel Nitelikli Kişisel Verilerin fiziksel ortamda işlenmesi durumunda, Verilerin işlendiği, muhafaza edildiği ve/veya erişildiği fiziksel ortamlar ile ilgili olarak Şirket:</li>
                    </ul>
                     
                    <ul>
                        <li>Özel Nitelikli Kişisel Verilerin bulunduğu ortamın niteliğine göre yeterli güvenlik önlemlerinin (elektrik kaçağı, yangın, su baskını, hırsızlık vb. durumlara karşı) alındığından emin olacaktır.</li>
                        <li>Bu ortamların fiziksel güvenliğini sağlayarak yetkisiz giriş çıkışları engelleyecektir.</li>
                    </ul>
                     
                    <ul>
                        <li>Özel Nitelikli Kişisel Verilerin aktarılması halinde, Veri Sorumlusu:</li>
                    </ul>
                     
                    <ul>
                        <li>Özel Nitelikli Kişisel Verilerin e-posta yoluyla aktarılmasının gerekmesi halinde şifreli kurumsal e-posta adresi veya Kayıtlı Elektronik Posta (“<strong>KEP</strong>”) hesabı kullanacaktır.</li>
                        <li>Özel Nitelikli Kişisel Verilerin taşınabilir bellek, CD, DVD gibi ortamlar yoluyla aktarımın gerekli olması halinde kriptografik yöntemlerle şifreleme yapar ve kriptografik anahtarı farklı ortamda tutacaktır.</li>
                        <li>Özel Nitelikli Kişisel Verilerin farklı fiziksel ortamlardaki sunucular arasında aktarılması gerekiyorsa, sunucular arasında VPN kurarak veya SFTP yöntemiyle aktarımı gerçekleştirecektir.</li>
                        <li>Özel Nitelikli Kişisel Verilerin kâğıt ortamı yoluyla aktarımının gerekli olması halinde, evrakın çalınması, kaybolması ya da yetkisiz kişiler tarafından görülmesi gibi risklere karşı gerekli önlemleri alır ve evrakı “gizlilik dereceli belgeler” formatında gönderecektir.</li>
                    </ul>
                     
                    <ul>
                        <li>Yukarıdaki düzenlemelere ek olarak, Şirket, Özel Nitelikli Veriler dahil Kişisel Verilerin güvenliğinin sağlanmasına ilişkin Kurul tarafından yayımlanan başta Kişisel Veri Güvenliği Rehberi ile “Özel Nitelikli Kişisel Verilerin İşlenmesinde Veri Sorumlularınca Alınması Gereken Yeterli Önlemler” ile ilgili Kurul’un 31/01/2018 Tarihli ve 2018/10 Sayılı Kararı olmak üzere KVK Düzenlemelerine uygun hareket edecektir.</li>
                    </ul>
                     
                    <h3><strong>VIII.    </strong><strong>KİŞİSEL VERİLERİN SAKLANMA SÜRESİ</strong></h3>
                     
                    <p>Kişisel Veriler, Şirket bünyesinde ilgili yasal saklama süreleri müddetince bulundurulmakta olup, bu verilerle ilişkili faaliyetlerin ve işbu Politika’da da belirtilen amaçların gerçekleştirilmesi için gerekli süre boyunca saklanmaktadır. Kullanım amacı sonlanan ve yasal saklama süresi sona eren Kişisel Veriler ise, KVKK’nın 7’nci maddesi uyarınca Şirket tarafından silinmekte, yok edilmekte veya anonim hale getirilmektedir.</p>
                     
                    <h3><strong>IX. </strong><strong>KİŞİSEL VERİLERİN SİLİNMESİ, YOK EDİLMESİ VE ANONİM HALE GETİRİLMESİ</strong></h3>
                     
                    <ul>
                        <li>Kişisel Verinin İşlenmesine yönelik meşru amaç ortadan kalktığında, ilgili Kişisel Veriler Silinir, Yok Edilir yahut Anonim Hale Getirilir.</li>
                    </ul>
                     
                    <ul>
                        <li>Silme, Yok Etme ve Anonim Hale Getirme süreçlerinin işletilmesinden Şirket sorumludur ve bu kapsamda Şirket tarafından gerekli prosedürler oluşturulur.</li>
                    </ul>
                     
                    <ul>
                        <li>Şirket, Kişisel Verileri gelecekte kullanma ihtimalini göz önünde bulundurarak saklamaz.</li>
                    </ul>
                     
                    <h3><strong>X.</strong><strong>KİŞİSEL VERİLERİN AKTARILMASI VE KİŞİSEL VERİLERİN ÜÇÜNCÜ KİŞİLER TARAFINDAN İŞLENMESİ</strong></h3>
                    <p>Şirket, Kişisel Veri İşleme amaçları doğrultusunda gerekli önlemleri alarak Kişisel Verileri yurtiçinde ve/veya yurtdışında bulunan üçüncü bir gerçek ya da tüzel kişiye KVK Düzenlemelerine uygun şekilde aktarabilir. Bu durumda Şirket, Kişisel Veri aktardığı üçüncü kişilerin de işbu Politika’ya uymasını sağlar ve Kişisel Verileri aktarmadan önce ve aktarım sırasında üçüncü kişilerin KVK Düzenlemelerine uyumunu denetler. Bu kapsamda üçüncü kişi ile akdedilen sözleşmelere gerekli koruyucu düzenlemeler eklenir.</p>
                    <p><strong>Türkiye’de Bulunan Üçüncü Kişilere Kişisel Veri </strong><strong>Aktarımı</strong></p>
                    <ul>
                        <li>Kişisel Veriler, KVKK Madde 5.2’de ve yeterli önlemler alınmak kaydıyla Madde 6.3’de belirlenen istisnai hallerde Açık Rıza olmaksızın yahut diğer hallerde Veri Öznesinin Açık Rızası alınmak şartı ile (KVKK Madde 5.1 ve Madde 6.2) Türkiye’de bulunan üçüncü kişilere Şirket tarafından aktarılabilir.</li>
                    </ul>
                     
                    <ul>
                        <li><strong>Yurt Dışında Bulunan Üçüncü Kişilere </strong><strong>Kişisel Veri Aktarımı</strong></li>
                    </ul>
                    <ul>
                        <li>Kişisel Veriler, KVKK Madde 5.2 ve Madde 6.3’de belirlenen istisnai hallerde Açık Rıza olmaksızın yahut diğer hallerde Veri Öznesinin Açık Rızası alınmak şartı ile (KVKK Madde 5.1 ve Madde 6.2) yurt dışında bulunan üçüncü kişilere, Şirket tarafından aktarılabilir.</li>
                    </ul>
                     
                    <ul>
                        <li>Kişisel Verilerin KVK Düzenlemelerine uygun olarak Açık Rıza alınmaksızın aktarıldığı durumda ayrıca aktarılacağı yabancı ülke bakımından aşağıdaki koşullardan birinin varlığı gerekir:</li>
                    </ul>
                     
                    <ul>
                        <li>Kişisel Verilerin aktarıldığı yabancı ülkenin Kurul tarafından yeterli korumanın bulunduğu ülkeler statüsünde olması (liste için lütfen Kurul’un güncel listesini takip ediniz),</li>
                        <li>Aktarımın gerçekleşecek olduğu yabancı ülkenin Kurul’un güvenli ülkeler listesinde yer almaması halinde Şirketin ve ilgili ülkedeki Veri Sorumlularının yeterli korumanın sağlanacağına ilişkin Kurul’un belirlediği esaslara uygun şekilde yazılı taahhütte bulunarak Kuruldan izin alması.</li>
                    </ul>
                    <h3><strong>XI.</strong><strong>ŞİRKETİN AYDINLATMA YÜKÜMLÜLÜĞÜ</strong></h3>
                    <h3>Şirket, KVKK’nın 10. maddesine uygun olarak, Kişisel Verilerin İşlenmesinden önce Veri Öznelerini aydınlatır. Bu kapsamda Şirket, Kişisel Verilerin elde edilmesi sırasında Aydınlatma Yükümlülüğünü yerine getirir. Aydınlatma Yükümlülüğü kapsamında Veri Öznelerine yapılacak olan bildirim sırasıyla şu unsurları içerir:</h3>
                    <ul>
                        <li>Veri Sorumlusunun ve varsa temsilcisinin kimliği,</li>
                        <li>Kişisel Verilerin hangi amaçla işleneceği,</li>
                        <li>İşlenen Kişisel Verilerin kimlere ve hangi amaçla aktarılabileceği,</li>
                        <li>Kişisel Veri toplamanın yöntemi ve hukuki sebebi,</li>
                        <li>Veri Öznelerinin KVKK Madde 11’de sayılan hakları.</li>
                    </ul>
                     
                    <ul>
                        <li>Şirket, KVKK’nın 11. maddesine uygun olarak Veri Öznesinin bilgi talep etmesi halinde gerekli bilgilendirmeyi yapar.</li>
                    </ul>
                     
                    <h3><strong>XII.  </strong><strong>VERİ ÖZNELERİNİN HAKLARI</strong></h3>
                    <h3>Şirket Kişisel Verisini elinde bulundurduğu Veri Öznelerinin aşağıda belirtilen kendileriyle ilgili taleplerine KVK Düzenlemelerine uygun şekilde cevap verir:</h3>
                     
                    <ul>
                        <li>Şirket tarafından Kişisel Veri İşlenip İşlenmediği öğrenme,</li>
                        <li>Kişisel Verilerinin işlenmesi halinde buna ilişkin bilgi talep etme,</li>
                        <li>Kişisel Verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,</li>
                        <li>Yurt içinde veya yurt dışında Kişisel Verilerin aktarıldığı üçüncü kişileri bilme,</li>
                        <li>Kişisel Verilerin Şirket tarafından eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme,</li>
                        <li>Amaç, süre ve meşruiyet prensipleri dâhilinde değerlendirilmek üzere Kişisel Verilerin İşlenmesini gerektiren sebeplerin ortadan kalkması halinde Şirket tarafından Kişisel Verilerin Silmesini veya yok edilmesini isteme,</li>
                        <li>Şirket tarafından Kişisel Verilerin düzeltilmesi, Silinmesi ya da Yok Edilmesi halinde bu işlemlerin Kişisel Verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
                        <li>İşlenen Kişisel Verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi durumunda Veri Öznesinin aleyhine bir sonucun ortaya çıkması halinde bu sonuca itiraz etme,</li>
                        <li>Kişisel Verilerin kanuna aykırı olarak işlenmesi ve bu sebeple Veri Öznesinin zarara uğraması hâlinde zararın giderilmesini talep etme.</li>
                    </ul>
                     
                    <ul>
                        <li>Veri Özneleri haklarını kullanmak istediği ve/veya Kişisel Verileri işlerken Şirketin işbu Politika kapsamında hareket etmediğini düşündüğü durumlarda taleplerini, Şirket veya kendi taleplerini Kişisel Verileri Koruma Kurumu tarafından belirlenen şartları taşıyacak şekilde oluşturarak aşağıdaki iletişim adresleri üzerinden ıslak imzalı bir dilekçe ile elden, noter veya iadeli taahhütlü mektup aracılığıyla veya kayıtlı elektronik posta (KEP) adresi, güvenli elektronik imza, mobil imza ya da daha önce tarafımıza bildirilen ve sistemimizde kayıtlı olan e-posta adresinizi kullanmak suretiyle şirketimize iletebilirsiniz. Lütfen güncel başvuru yöntemlerini başvuru öncesinde mevzuattan teyit ediniz.</li>
                    </ul>
                     
                    <p><strong>          Posta adresi     : </strong> </p>
                    <p><strong>          E- posta adresi: </strong></p>
                    <p><strong>         Kep adresi :     </strong></p>
                        <ul>
                            <li>Veri Öznelerinin yukarıda sıralanan haklarına ilişkin taleplerini yazılı olarak Şirkete iletmeleri durumunda Şirket talebin niteliğine göre talebi en geç otuz gün içinde ücretsiz olarak sonuçlandırır. Taleplerin Veri Sorumlusu tarafından sonuçlandırılmasına ilişkin ayrıca bir maliyet doğması hâlinde Kişisel Verileri Koruma Kurulunca belirlenen tarifedeki ücretler Veri Sorumlusu tarafından talep edilebilir.</li>
                        </ul>
                         
                        <h3><strong>XIII. </strong><strong>VERİ YÖNETİMİ VE GÜVENLİĞİ</strong></h3>
                        <p>Şirket, KVK Düzenlemeleri kapsamındaki yükümlülüklerini yerine getirmek, işbu Politikanın uygulanması için gerekli KVK Prosedürlerinin uygulanmasını sağlamak ve denetlemek, bunların işleyişine yönelik önerilerde bulunmak üzere gerekli teşkilatı oluşturur.</p>
                         
                        <ul>
                            <li>Şirket işbu Politika ve KVK Düzenlemelerinin uygulamasının sağlanması ve denetlenmesi kapsamında yetkili kişiler belirleyebilir ve/veya üçüncü kişi iş ortaklarımızdan gerekli desteği alabilir.</li>
                        </ul>
                         
                        <ul>
                            <li>Şirket tarafından Kişisel Veri İşleme faaliyetleri teknolojik imkanlar ve uygulama maliyetine göre teknik sistemlerle denetlenmektedir.</li>
                        </ul>
                        <ul>
                            <li>Şirket, Kişisel Verilerin korunmasına ve hukuka uygun olarak işlenmesine yönelik olarak Şirket içi farkındalığın artırılması kapsamında çalışanlarını bilgilendirmekte, eğitmekte ve periyodik olarak çalışanlarına yönelik gerekli denetimleri yapmaktadır.</li>
                        </ul>
                         
                        <ul>
                            <li>Şirket çalışanları, Kişisel Verilere üzerinde yalnızca kendilerine tanımlanan yetki dâhilinde ve ilgili KVK Prosedürüne uygun olarak erişebilir.</li>
                        </ul>
                         
                        <ul>
                            <li>Şirket bünyesinde erişim kısıtlamaları yapılmış ve yetki sınırlamaları tanımlanmıştır.</li>
                        </ul>
                         
                        <ul>
                            <li>Şirkette Kişisel Verilerin güvenli ortamlarda saklanması için teknolojik gelişmelere uygun olarak virüs koruma sistemleri ve güvenlik duvarlarını içeren yazılımlar ve donanımlar kurulmaktadır. Güvenlik açıklarının tespit edilmesi için düzenli olarak güvenlik taramaları teknik yeterlilikte kişiler tarafından yapılmaktadır.</li>
                        </ul>
                         
                        <ul>
                            <li>Şirkette Kişisel Verilerin kaybolmasını yahut zarar görmesini engellemek üzere yedekleme programları kullanılmakta ve yeterli düzeyde güvenlik önlemleri alınmaktadır.</li>
                        </ul>
                         
                        <ul>
                            <li>Şirkette Kişisel Verilerin yer aldığı belgelerin kriptolu (şifreli) sistemlerle korunması için gerekli önlemler alınacaktır. Bu kapsamda, Kişisel Veriler ortak alanlarda ve masaüstünde saklanmayacaktır. Kişisel Verilerin yer aldığı dosya ve klasörler vb. belgeler masaüstüne veya ortak klasöre taşınmayacak, Şirket bilgisayarlarındaki bilgiler USB vb. başka bir aygıta aktarılamayacak, Şirket dışına çıkartılamayacaktır.</li>
                        </ul>
                         
                        <ul>
                            <li>Şirket içerisinde işlenen Kişisel Verilerin tamamı Şirket tarafından “Gizli Bilgi” olarak kabul edilir.</li>
                        </ul>
                         
                        <ul>
                            <li>Şirket çalışanları, Kişisel Verilerin güvenliğine ve gizliliğine ilişkin yükümlülüklerinin, iş ilişkisinin sona ermesinden sonra da devam edeceği konusunda bilgilendirilmiş ve Şirket çalışanlarından bu kurallara uymaları yönünde taahhüt alınmıştır.</li>
                        </ul>
                         
                        <h3><strong>XIV.</strong><strong>EĞİTİM</strong></h3>
                        <h3>Şirket, Kişisel Verilerin korunması konusunda çalışanlarına Politika ve ekinde yer alan KVK Prosedürleri ile KVKK Düzenlemeleri kapsamında gerekli eğitimleri verir.</h3>
                         
                        <ul>
                            <li>Eğitimlerde Özel Nitelikli Kişisel Verilerin tanımlarına ve korunmasına yönelik uygulamalara özellikle değinilir.</li>
                        </ul>
                         
                        <ul>
                            <li>Şirket çalışanı Kişisel Verilere fiziksel olarak veya bilgisayar ortamında erişiyorsa, Şirket ilgili çalışanına bu erişimler özelinde (örneğin erişilen bilgisayar programı) eğitim verir.</li>
                        </ul>
                         
                        <h3><strong>XV. </strong><strong>DENETİM</strong></h3>
                        <p>Şirket, işbu Politika ve KVK Düzenlemelerine Şirketin tüm çalışanları, departmanları ve yüklenicilerinin uygun hareket ettiğini düzenli olarak hiçbir ön bildirimde bulunmaksızın her zaman ve re’sen denetleme hakkını haizdir ve bu kapsamda gerekli rutin denetimleri yapar. Şirket bu denetimlere dair KVK Prosedürü oluşturur ve anılan prosedürün uygulanmasını sağlar.</p>
                         
                        <h3><strong>XVI. </strong><strong>İHLALLER</strong></h3>
                        <h3>Şirketin her bir çalışanı, KVK Düzenlemelerinde ve işbu Politika kapsamında belirtilen usul ve esaslara aykırı olduğunu düşündüğü iş, işlem yahut eylemi en kısa sürede Şirket’e raporlar. Bu kapsamda ilgili ihlale yönelik Şirket, işbu Politika ve KVK Prosedürlerine uygun şekilde eylem planı oluşturur.</h3>
                         
                        <ul>
                            <li>Yapılan bilgilendirmeler sonucunda Şirket KVK Düzenlemeleri başta olmak üzere konuya ilişkin yürürlükteki mevzuat hükümlerini dikkate alarak ihlale ilişkin Veri Öznesi veya Kurum’a yapılacak bildirimi hazırlar.</li>
                        </ul>
                         
                        <h3><strong>XVII. </strong><strong>POLİTİKADA YAPILACAK DEĞİŞİKLİKLER</strong></h3>
                        <p>Şirket tarafından işbu Politika zaman zaman Ortaklar Kurulu onayı ile değiştirilebilir.</p>
                         
                        <ul>
                            <li>Şirket, Politika üzerinde yaptığı değişiklikler incelenebilecek şekilde güncellenen Politika metnini e-posta yolu ile çalışanlarıyla paylaşır veya aşağıdaki web adresi üzerinden çalışanların ve Veri Öznelerinin erişimine sunar.</li>
                        </ul>
                         
                        <p><strong>İlgili web adresleri: </strong><a href="https://www.sahin724.com/Index.html">https://www.sahin724.com/Index.html</a></p>
                         
                        <h3><strong>XVIII.</strong><strong>POLİTİKANIN YÜRÜRLÜK TARİHİ</strong></h3>
                         
                        <p>İşbu Politika’nın işbu versiyonu <strong>10/12/</strong><strong>2019 </strong>tarihinde Şirket Ortaklar Kurulu tarafından onaylanarak yürürlüğe girmiştir.</p>
                </>
    );
}

export default Kvkk;