import React ,{ useState} from 'react';
import {Offcanvas,Button,InputGroup,FormControl} from 'react-bootstrap';
import { CategoryList, slugify } from "../../constans";
import { Link, withRouter } from 'react-router-dom';
import Logo from "../../images/sahinlogo.svg";
import { mobileMenu,phoneIcon,login, mobilePhone ,orderTracking} from '../../constans/svgExport';
import { getSearchKey } from '../../api/service/generalService';

const MobileHeader = () => {
    const [show, setShow] = useState(false);
    const [searchFocus, setSearchFocus] = useState(false);
    const [result, setResult] = useState([]);
    const [subCategory, setSubCategory] = useState({
      show:false,
      element:''
    });
    const [search, setSearch] = useState('');
    const loggedIn = JSON.parse(localStorage.getItem('loggedIn'));

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    const handleFocus = () => {
      setSearchFocus(true);
    }

    const handleUnFocus = () => {
      setSearchFocus(false);

    }

    const handleChange = async (e) => {
     const key = e.target.value;
      if(e.target.value !== "" && e.target.value !== null && e.target.value !== undefined) {
        const res = await getSearchKey(key);
        if(!res.errStatus){
          localStorage.setItem('searchResult',JSON.stringify(res.data));
          setResult(res.data);
          setSearch(key);
        }
      }

    }

    const handleMobileMenuClick = (category) => {
      if(subCategory.show) {
        setSubCategory({show:false,element:''});

      }
      else {
          const element = []
          element.push(
            <li key="tm-sub">
              <Link
                className="tm"
                  to={category.KategoriUrl}
                  key={category.ID}
                  onClick={() => setShow(false)}
                >
                Tümünü İncele
              </Link>
              </li>
          )
          category.SubCategory.map((e) => (
            element.push(
              <li key={e.ID}>
                <Link
                  to={e.KategoriUrl}
                  key={e.ID}
                  onClick={() => setShow(false)}
                >
                {e.KategoriAdi}
              </Link>
            </li>
            )
          )) 
          setSubCategory({show:true, element});

      }
    }

    return (
      <>
        <div className="search-menu-part">
          <Button variant="primary" className="mobile-menu-button" onClick={handleShow}>
              {mobileMenu()} Menü
            </Button>
            <InputGroup>
              <FormControl onMouseOut={handleUnFocus} onChange={(e) => handleChange(e)} onFocus={handleFocus} id="search-input" aria-label="Aramak istediğiniz kelimeyi yazınız." placeholder="Ör: Sucuk"/>
              <InputGroup.Text>
                {search !== "" ?  <Link to={'/search-result?arama='+search}>Ara</Link> : "Ara"}
              </InputGroup.Text>
          </InputGroup>
         
        </div>
        <ul style={{display: searchFocus ? 'block' : 'none'}} className="search-result">
            {result.length > 0 ?
                result.map((product) =>{
                  return(
                      <div key={product.UrunId} className="product-list">
                        <Link to={ (slugify(product.kategoriName.toLowerCase()) +'/' +slugify(product.UrunAdi)+"/"+product.UrunId)}>
                            <img src={"https://www.sahin724.com/products/"+product.UrunImage} alt={product.UrunImage}/>
                              <h6>{product.UrunAdi}</h6>
                              <p>{product.UrunFiyat} TL</p>
                        </Link>
                      </div>
                  );
                })
            :  
            ''}
        </ul>

        <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
                <Link to="/"  onClick={() => setShow(false)}>
                  <img src={Logo} alt="Logo" />
                </Link>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>

              <ul>
                {CategoryList.map((category) => {
                    if(category.SubCategory === undefined) {
                      return (
                        <Link
                        to={category.KategoriUrl}
                        key={category.ID}
                        onClick={() => setShow(false)}
                      >
                        <li>
                            {category.KategoriAdi}
                        </li>
                        </Link>

                      );
                    }
                    else {
                      return (
                        <>
                          <Button
                          key={category.ID}
                          onClick={() => handleMobileMenuClick(category)}
                        >
                          <li className={ subCategory.show ? 'active' :''} style={{display: subCategory.show ? 'block' :'flex'}}>
                          
                              {category.KategoriAdi}
                        
                          </li>
                          </Button>
                            {subCategory.show ?
                              <div style={{display: subCategory.show ? 'block' : 'none'}}>
                                {subCategory.element}
                              </div> : ''} 
                            </>

                      );
                    }
                  
                  })
                }
                </ul>
                <div className="login-and-order-grid"> 
                  {
                      loggedIn ? 
                        <Link to="/account/hesabim" onClick={() => setShow(false)}>
                        <div className="header-icon-box">
                            {login()}
                        </div>
                        <div className="header-name-box">                              
                          <span>Hesabım</span>
                        </div>
                      </Link>
                      :
                        <Link to="/account/login" onClick={() => setShow(false)} >
                          <div className="header-icon-box">
                              {login()}
                          </div>
                          <div className="header-name-box">
                            <p>Giriş Yap</p>
                          </div>
                        </Link>
                    }
                    <a href="tel:4441371">
                    {phoneIcon()}
                    <span>Ara Gelsin</span>
                  </a>
                  <Link to="/sayfalar/siparis-sorgula"    onClick={() => setShow(false)}>
                    {orderTracking()}
                    <span>Sipariş Takibi</span>
                </Link>

                </div>
           
              <div className="mobile-phone">
                <a href="tel:4441371"> {mobilePhone()} 444 13 71 </a>
              </div>
          </Offcanvas.Body>
        </Offcanvas>
      </>
    );
}

export default withRouter(MobileHeader);