import React , {useEffect,useState, useRef} from 'react';
import {deleteByAddress,searchByDistrict,searchCityList,updateByAddress} from '../../../api/service/loginServices';
import locationIcon from '../../../images/location.svg';
import removeIcon from '../../../images/remove.svg';
import editIcon from '../../../images/edit.svg';
import { Button,Modal,Form,Row,Col} from 'react-bootstrap';
import { Formik ,Field} from 'formik';
import InputMask from 'react-input-mask';


function AddressList(props) {
    const [cityList, setCityList] = useState([]);
    const [selectedCity, setSelectedCity] = useState();
    const [selectedDistrict, setSelectedDistrict] = useState();
    const [distirictList, setDistirictList] = useState([]);
    const [coporateAddress, setCorporateAddress] = useState(false);
    const [show, setShow] = useState(false);
    const [selectedData, setSelectedData] = useState(false);
    const [deleteShow, setDeleteShow] = useState(false);
    const form = useRef(null);
    useEffect(() => {
        const loggedIn =  JSON.parse(localStorage.getItem('loggedIn'))
        if(loggedIn && !props.addressList){
            if(cityList.length === 0) {
                getCityList();

            }
        }
    });
 

    const handleCorporateAddress = () => {
        if(!coporateAddress) {
            setCorporateAddress(true);
        }
        else {

            setCorporateAddress(false);
        }

    }

    const handleDeleteClose = () => setDeleteShow(false);


    const getCityList = async () => {
        const res = await searchCityList();
        if(!res.errStatus) {
            res.data = res.data.sort(function (a, b) {
                return a.Sehir.localeCompare(b.Sehir); //using String.prototype.localCompare()
            });
            setCityList(res.data);
        }
    }
    
    const handleClose = () => {
        setShow(false);
        setCorporateAddress(false)
    }
    const handleShow = async (data) =>{
        await handleCityChange(data.Sehir)
        setShow(true);
        setSelectedData(data);

    } 
    
    const handleCityChange = async (e) => {
        if(typeof(e) === "number") {
            setSelectedCity(e);
            const distiricts = await searchByDistrict(e);
            if(!distiricts.errStatus) {
                setDistirictList(distiricts.data);
            }
        }
        else {
            setSelectedCity(e.target.value);
            const distiricts = await searchByDistrict(e.target.value);
            if(!distiricts.errStatus) {
                setDistirictList(distiricts.data);
            }
        }
        
    }

    const handleDistrictChange = (e) => {
        setSelectedDistrict(e.target.value);
    }


    const handleDeleteAddress = async (adres) => {
        setSelectedData(adres);
        setDeleteShow(true);
        if(deleteShow){
            const res = await deleteByAddress(selectedData.ID);
            if(!res.errStatus) {
                await props.getAddressLists();
                setDeleteShow(false);
            }
        }

    }

    const handleIndividualAddress = () => {
        setCorporateAddress(false);
    }
 
    const handleSubmit = async (event) => {       
        const updateAddress = {};
        updateAddress.AdSoyad = event.AdSoyad !== undefined ? event.AdSoyad : selectedData.AdSoyad;
        updateAddress.Adres = event.Adres !== undefined ? event.Adres : selectedData.Adres;
        updateAddress.AdresAdi = event.AdresAdi !== undefined ? event.AdresAdi : selectedData.AdresAdi;
        updateAddress.CepTel = event.CepTel !== undefined ? event.CepTel : selectedData.CepTel;
        updateAddress.Sehir = selectedCity !== undefined ? selectedCity: selectedData.Sehir;
        updateAddress.Ilce = selectedDistrict !==undefined? selectedDistrict : selectedData.Ilce === undefined ? null :selectedData.Ilce;
        updateAddress.ID = selectedData.ID;
        updateAddress.VergiNo = event.VergiNo;
        updateAddress.VergiDaire = event.VergiDaire
        const res = await updateByAddress(updateAddress);
        if(res.data.Sonuc) {
            await props.getAddressLists();
            handleClose();
        }
    };

    if(Array.isArray(props.addressList)) {
        return(
            <>
            <div className="address-list-box">
                    {props.addressList.map((address,i) => {
                        return(
                            <div className="address-list" key={i}>
                                {/* <div className="location-box">
                                     <img src={locationIcon} alt="adres icon"  />

                                </div> */}
                                <div className="address-box">
                                    <h4>{address.AdresAdi}</h4>
                                    <p>{address.AdSoyad}</p>
                                    <p> {address.Adres} {address.Iller} </p>
                                    <p>{address.CepTel}</p>
                                </div>
                                <div className="button-box">
                                    <Button onClick={() => handleShow(address)}>
                                        <img src={editIcon} alt="edit-button" />
                                    </Button>
                                    <Button onClick={() => handleDeleteAddress(address)}>
                                            <img src={removeIcon} alt="remove-button" />
                                    </Button>
                                </div>
                            </div>

                        );

                    })}
                </div>
                {
                    selectedData ? 
                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                            <Modal.Title>"{selectedData.AdresAdi}" Adresini Güncelle</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                            <Formik
                                    onSubmit={handleSubmit}
                                    initialValues={{
                                        AdSoyad:selectedData.AdSoyad,
                                        Adres: selectedData.Adres,
                                        AdresAdi:selectedData.AdresAdi,
                                        CepTel:selectedData.CepTel,
                                        Sehir: selectedData.Sehir,
                                        Ilce: selectedDistrict,
                                        TcNo:  selectedData.TcNo,
                                        VergiDairesi: '',
                                        VergiNo: 0 

                                    }}
                                    >
                                    {({
                                        handleSubmit,
                                        handleChange,
                                        values,
                                        touched,
                                        errors,
                                    }) => (
                                    <Form ref={form} noValidate onHide={handleClose} onSubmit={handleSubmit}>
                                        <Row className="mb-12 address-edit-input-box">
                                        <label className="form-title">01 Teslim alacak kişinin bilgileri</label>
                                            <Form.Group as={Col} md="12" controlId="name" className="address-edit-input">
                                            <Form.Control
                                                    type="text" placeholder="Ad Soyad"
                                                    name="AdSoyad"
                                                    value={values.AdSoyad}
                                                    onChange={handleChange}
                                                    isValid={touched.lastName && !errors.lastName}
                                                /> 
                                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="12" controlId="phonenumber" className="address-edit-input">
                                                    <InputMask pattern="[0-9]*" style={{padding:'10px'}} placeholder="Telefon numaranız" mask="0 (999) 999 99-99"
                                                    defaultValue={selectedData.CepTel}
                                                    onChange={handleChange}
                                                    />
                                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                            </Form.Group>
                                            <label className="form-title">02 Adres Bilgileriniz</label>
                                            <Form.Group as={Col} md="12" controlId="adresName" className="address-edit-input">
                                            <Form.Control
                                                    type="text" placeholder="Adres Başlığı"
                                                    name="AdresAdi"
                                                    value={values.AdresAdi}
                                                    onChange={handleChange}
                                                    isValid={touched.lastName && !errors.lastName}
                                                /> 
                                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="12" controlId="city" className="address-edit-input">
                                                     <Field name="city" component="select" 
                                                        defaultValue={selectedData.Sehir}
                                                        onChange={handleCityChange}
                                                        >
                                                        <option >İl Seçini</option>
                                                            {
                                                            cityList.map((city) => {
                                                                    return(<option key={city.ID} value={city.ID}>{city.Sehir}</option>);

                                                                })
                                                            }
                                                    </Field>
                                            </Form.Group>
                                            <Form.Group as={Col} md="12" controlId="distirc" className="address-edit-input">
                                                <Field name="district" component="select" 
                                                        onChange={handleDistrictChange}
                                                       defaultValue={selectedData.Ilce}
                                                        >
                                                        <option >İlçe Seçiniz</option>
                                                        {
                                                            distirictList.length > 0 ?
                                                                distirictList.map((distirict) => {
                                                                    return(<option  key={distirict.Id} value={distirict.Id}>{distirict.Ilce}</option>);

                                                                }) : ""
                                                        }
                                                    </Field>
                                            </Form.Group>
                                          
                                            <Form.Group as={Col} md="12" controlId="adres" className="address-edit-input">
                                                   <Form.Control
                                                        as="textarea"
                                                        rows="2"
                                                        placeholder="Adres giriniz"
                                                        name="Adres"
                                                        value={values.Adres}
                                                        onChange={handleChange}
                                                        isValid={touched.lastName && !errors.lastName}
                                                        style={{ height: '100px' }}
                                                        /> 
                                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                            </Form.Group>
                                            <label className="form-title">03 Fatura Tipi</label>
                                            <Form.Group as={Col} md="12" controlId="adres" className="address-edit-input-checkbox">
                                                <Form.Check type="checkbox" id="check-api-bireysel" name="adrestip" >
                                                        <Form.Check.Input defaultChecked={selectedData.tip === "0"}  type="radio"  name="adrestip" isValid onChange={handleIndividualAddress} />
                                                        <Form.Check.Label  htmlFor="check-api-bireysel">Bireysel</Form.Check.Label>
                                                    </Form.Check>
                                                    <Form.Check type="checkbox" id="check-api-kurumsal" name="adrestip" >
                                                        <Form.Check.Input defaultChecked={selectedData.tip === "1"}   type="radio" name="adrestip" isValid  onChange={handleCorporateAddress}/>
                                                        <Form.Check.Label htmlFor="check-api-kurumsal" >Kurumsal</Form.Check.Label>
                                                    </Form.Check>
                                            </Form.Group>
                                            
                                                    <div className="kurumsal-address" style={{display:`${coporateAddress ? 'block' : 'none'}`}}>
                                                    <Form.Group as={Col} md="12" controlId="adresName" className="address-edit-input">            
                                                        <InputMask pattern="[0-9]*" style={{padding:'10px'}} name="VergiNo" placeholder="Vergi No" mask="9999999999"
                                                            defaultValue={selectedData.VergiNo}
                                                            onChange={handleChange}
                                                            />
                                                    </Form.Group>
                                                    <Form.Group as={Col} md="12" controlId="adresName" className="address-edit-input">
                                                    <Form.Control
                                                            type="text" placeholder="Vergi Dairesi"
                                                            name="VergiDairesi"
                                                            value={values.VergiDaire}
                                                            defaultValue={selectedData.VergiDairesi}
                                                            onChange={handleChange}
                                                            isValid={touched.lastName && !errors.lastName}
                                                        /> 
                                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                    </Form.Group>
                                            
                                                </div> 
                                            </Row>
                                        
                                            <Button variant="success" type="submit" style={{float:'right',width:'25%' }}>
                                                Güncelle
                                            </Button>
                                        </Form>
                                    )}
                                    </Formik>
                            </Modal.Body>
                    </Modal>
                    : ""
                }
                 <Modal className="delete-address-modal" show={deleteShow} centered onHide={handleDeleteClose}>
                    <Modal.Header closeButton>
                    <Modal.Title>Adresi silmek istediğinize emin misiniz ?</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                    <Button variant="danger" onClick={handleDeleteAddress}>
                        Adresi Sil
                    </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
    return(
        <>
            <div className="no-favorite">
                <img src={locationIcon} alt="favori icon" />
                <span> Henüz bir adres kaydetmediniz. </span>
            </div>

        </>
    );
   
}

export default AddressList;