import React, { useState } from 'react';
import {BackIcon} from '../../../constans/svgExport';
import { Button,Form ,Alert} from 'react-bootstrap';
import { sendPasswordEmail } from '../../../api/service/loginServices';

const ForgotMyPassord = (props) => {

    const [email,setEmail] = useState("");
    const [alertShow, setAlertShow] = useState(false);
    const handleChange = (e) => {
        setEmail(e.target.value)
    }

    const handleSubmit = async () => {
        const res = await sendPasswordEmail(email);
        if(!res.errStatus) {
            setAlertShow(true);
        }
    }

    return(
        <div className="forgot-password">
            <Button className="back-button"  onClick={() => props.handleBackClik('login')}> {BackIcon()}</Button>
            <h4>
                Şifre yenileme
            </h4>
            <Alert style={{display: alertShow ? 'block' : 'none' }} variant="success" >
                 Parola yenileme bilgileriniz mail adresinize gönderilmiştir.
            </Alert>
            <p>Şifre yenileme bağlantısını gönderebilmemiz için e-posta adresinize ihtiyacımız var.</p>
            <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control type="email" placeholder="E-postanızı giriniz"  onChange={(e) => handleChange(e)}/>
                </Form.Group>
                <Button variant="primary" disabled={alertShow ? true : false } type="button" onClick={handleSubmit}>
                    Devam Et
                </Button>
            </Form>
        </div>
    );
}

export default ForgotMyPassord;