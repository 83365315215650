import { postRequest , getRequest} from '../client/client';
import finalConfig from '../../constans/apiConstants';
import headers from '../../constans/header.json';
import { parseResult } from './dataParser';
import {servicesBaseUrl} from '../../constans'

export const searchCartCampaignCheck = async data => {
    const url = `${servicesBaseUrl+finalConfig.CARTCAMPAIGNCHECK}`;
    const config = headers.content_type.application_json;
    const result = await postRequest(url,data,config);
    const parsedResult = parseResult(result);
    return parsedResult;
};

export const createWithoutAccount = async data => {
    const url = `${servicesBaseUrl+finalConfig.WITHOUTACCOUNT}`;
    const config = headers.content_type.application_json;
    const result = await postRequest(url,data,config);
    const parsedResult = parseResult(result);
    return parsedResult;
};


export const createOrder = async data => {
    const url = `${servicesBaseUrl+finalConfig.ORDERCREATE}`;
    const config = headers.content_type.application_json;
    const result = await postRequest(url,data,config);
    const parsedResult = parseResult(result);
    return parsedResult;
};

export const checkoutPayment = async data => {
    const url = `${servicesBaseUrl+finalConfig.CHECKOUTPAYMENT}`;
    const config = headers.content_type.application_json;
    const result = await postRequest(url,data,config);
    const parsedResult = parseResult(result);
    return parsedResult;
};

export const directCheckoutPayment = async data => {
    const url = `${servicesBaseUrl+finalConfig.DIRECTCHECKOUTPAYMENT}`;
    const config = headers.content_type.application_json;
    const result = await postRequest(url,data,config);
    const parsedResult = parseResult(result);
    return parsedResult;
};

export const checkoutGarantiPayment = async data => {
    const url = `${servicesBaseUrl+finalConfig.CHECKOUTPAYMENT}`;
    const config = headers.content_type.application_json;
    const result = await postRequest(url,data,config);
    const parsedResult = parseResult(result);
    return parsedResult;
};


export const garantiPayCheckoutPayment = async data => {
    const url = `${servicesBaseUrl+finalConfig.GARANTIPAY}`;
    const config = headers.content_type.application_json;
    const result = await postRequest(url,data,config);
    const parsedResult = parseResult(result);
    return parsedResult;
};

export const orderSuccess = async data => {
    const url = `${servicesBaseUrl+finalConfig.ORDERSUCCESS}`;
    const config = headers.content_type.application_json;
    const result = await postRequest(url,data,config);
    const parsedResult = parseResult(result);
    return parsedResult;
};

export const searhByDirectOrder = async (payUrl) => {
    const url = servicesBaseUrl+finalConfig.DIRECTCHECKOUT + "?" + payUrl;
    const config = headers.content_type.application_json;
    const result = await getRequest(url,config);
    const parsedResult = parseResult(result);
    return parsedResult;
};

export const serchByOrderStatus = async (orderId) => {
    const url = servicesBaseUrl+finalConfig.ORDERSTATUS+"/"+orderId;
    const config = headers.content_type.application_json;
    const result = await getRequest(url,config);
    const parsedResult = parseResult(result);
    return parsedResult;
};

export const searchByOrderDetail = async (orderId) => {
    const url = servicesBaseUrl+finalConfig.GETORDERINFO+"/"+orderId;
    const config = headers.content_type.application_json;
    const result = await getRequest(url,config);
    const parsedResult = parseResult(result);
    return parsedResult;
};