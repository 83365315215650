import React,{ useEffect, useRef, useState} from 'react';
import { Form,Button,Col,Row ,Alert, Container} from 'react-bootstrap';
import {withRouter, useHistory} from 'react-router-dom';
import { Formik } from 'formik';
import { searchPasswordLink, updatePassword } from '../../../api/service/loginServices';

function ResetPassword() {
    const form = useRef(null);
    const history = useHistory();
    const [warning,setWarning] = useState({
        message:'',
        show:false,
        variant:'success',
        userId:0,
    });

    useEffect(() => {
        document.body.classList.add('reset-password');
        if(warning.userId ===0 && !warning.show ){
            PageLinkControl();

        }
    });

    async function PageLinkControl() {
        if(window.location.href.split('?').length > 1) {
            const linkRes = await searchPasswordLink('?'+window.location.href.split('?')[1]);
            if(!linkRes.errStatus) {
                if(!linkRes.data.Sonuc) {
                    history.push("/");
                }
                else {
                    setWarning({userId: linkRes.data.UserId})
                }
            }
            else {
                history.push("/");
            }
        }
        else {
            history.push("/");
        }
    }

    useEffect(() => {
        return () => {
            document.body.classList.remove('reset-password');
        };
    }, []);

    const handleSubmit = async (event) => {
       if(event.password !== event.passwordConfirm){
            setWarning({message:'Şifreleriniz uyşmamaktadır.', variant:"danger", show: true});
       }
       else {
           // eslint-disable-next-line
            const link = "?userId="+warning.userId.toString()+"&"+"password="+event.password;
            const linkRes = await updatePassword(link);
            if(!linkRes.errStatus) {
                setWarning({message:'Şifreniz başarıyla değiştirildi girişe yönlendiriyorsunuz...', variant:"success", show: true});
                setTimeout(function(){
                    history.push("/account/login");
                }, 3000);
            }
       }
    };

    return(
        <>
            <Container fluid className="forgot-password-full-part">
                <Container className="forgot-password-normal-part">
                    <div className="forgot-password-grid-part">
                        <h3>Şifremi Yenile</h3>
                    <Alert variant={warning.variant} style={{display: warning.show ? 'block' : 'none'}}>
                    {warning.message}
                    </Alert>
                    <Formik
                        onSubmit={handleSubmit}
                        initialValues={{
                            password: '',
                            passwordConfirm: '',
                        }}
                        >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors,
                        }) => (
                        <Form ref={form} noValidate onSubmit={handleSubmit}>
                            <Row className="mb-12 login-input-box">
                                <Form.Group as={Col} md="12" controlId="userpassword" className="email-input">
                                    <Form.Control
                                        type="password" placeholder="Yeni paralonuzu giriniz."
                                        name="password"
                                        value={values.password}
                                        onChange={handleChange}
                                        isValid={touched.firstName && !errors.firstName}
                                    />
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="passwordconfirm" className="password-input">
                                        <Form.Control
                                            type="password" placeholder="Şifrenizi tekrar giriniz"
                                            name="passwordConfirm"
                                            value={values.passwordConfirm}
                                            onChange={handleChange}
                                            isValid={touched.lastName && !errors.lastName}
                                        />
                                    </Form.Group>
                                </Row>
                                <Button variant="primary" type="submit">
                                Şifremi Yenile
                                </Button>
                            </Form>
                        )}
                        </Formik>
                    </div>
                   
                        
                </Container>
            </Container>
       
        </>
    );
}



export default withRouter(ResetPassword);