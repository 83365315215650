import { getRequest,postRequest } from '../client/client';
import finalConfig from '../../constans/apiConstants';
import headers from '../../constans/header.json';
import { parseResult } from './dataParser';
import {servicesBaseUrl} from '../../constans'


export const searchAllSlide= async () => {
    const url = servicesBaseUrl+finalConfig.SLIDER;
    const config = headers.content_type.application_json;
    const result = await getRequest(url, config);
    const parsedResult = parseResult(result);
    return parsedResult;
};

export const searchAllCategory = async () => {
    const url = servicesBaseUrl+finalConfig.CATEGORY;
    const config = headers.content_type.application_json;
    const result = await getRequest(url, config);
    const parsedResult = parseResult(result);
    return parsedResult;
};


export const searchCategoryProducts = async (normalizedName) => {
    const url = servicesBaseUrl+finalConfig.CATEGORYPRODUCTS+normalizedName;
    const config = headers.content_type.application_json;
    const result = await getRequest(url, config);
    const parsedResult = parseResult(result);
    return parsedResult;
};


export const searchHomeProducts = async () => {
    const url = servicesBaseUrl+finalConfig.HOMEPRODUCTS;
    const config = headers.content_type.application_json;
    const result = await getRequest(url, config);
    const parsedResult = parseResult(result);
    return parsedResult;
};

export const searchAllProducts = async () => {
    const url = servicesBaseUrl+finalConfig.SEARCHALLPRODUCT;
    const config = headers.content_type.application_json;
    const result = await getRequest(url, config);
    const parsedResult = parseResult(result);
    return parsedResult;
};

export const searchAllComment = async () => {
    const url = servicesBaseUrl+finalConfig.COMMENT;
    const config = headers.content_type.application_json;
    const result = await getRequest(url, config);
    const parsedResult = parseResult(result);
    return parsedResult;
};

export const searchByProductDetail = async id => {
    const url = `${servicesBaseUrl+finalConfig.PRODUCTDETAİL}/${id}`;
    const config = headers.content_type.application_json;
    const result = await postRequest(url, config);
    const parsedResult = parseResult(result);
    return parsedResult;
};


export const searchByOrderNo = async orderNo => {
    const url = `${servicesBaseUrl+finalConfig.SEARCHORDER}/${orderNo}`;
    const config = headers.content_type.application_json;
    const result = await getRequest(url, config);
    const parsedResult = parseResult(result);
    return parsedResult;
};


export const getSearchKey = async searchkey => {
    const url = `${servicesBaseUrl+finalConfig.SEARCH}/${searchkey}`;
    const config = headers.content_type.application_json;
    const result = await getRequest(url, config);
    const parsedResult = parseResult(result);
    return parsedResult;
};

export const searchAllLezzetler = async () => {
    const url = servicesBaseUrl+finalConfig.LEZZETLERTARIFLER;
    const config = headers.content_type.application_json;
    const result = await getRequest(url, config);
    const parsedResult = parseResult(result);
    return parsedResult;
};
export const searchAllHomeLezzetler = async () => {
    const url = servicesBaseUrl+finalConfig.HOMELEZZETLER;
    const config = headers.content_type.application_json;
    const result = await getRequest(url, config);
    const parsedResult = parseResult(result);
    return parsedResult;
};

export const searchByIdLezzetler = async (id) => {
    const url = servicesBaseUrl+finalConfig.LEZZETLERDETAIL+id;
    const config = headers.content_type.application_json;
    const result = await getRequest(url, config);
    const parsedResult = parseResult(result);
    return parsedResult;
};

export const createComment = async (data) => {
    const url = servicesBaseUrl+finalConfig.CREATECOMMENT;
    const config = headers.content_type.application_json;
    const result = await postRequest(url, data,config);
    const parsedResult = parseResult(result);
    return parsedResult;
};


export const searhCargo = async (cargoNo) => {
    const url = servicesBaseUrl+finalConfig.SEARHCARGO + "?ky=" + cargoNo;
    const config = headers.content_type.application_json;
    const result = await getRequest(url,config);
    const parsedResult = parseResult(result);
    return parsedResult;
};

export const searchAllPageList = async () => {
    const url = servicesBaseUrl+finalConfig.PAGELIST;
    const config = headers.content_type.application_json;
    const result = await getRequest(url, config);
    const parsedResult = parseResult(result);
    return parsedResult;
};

export const searchXmlList = async () => {
    const url = servicesBaseUrl+finalConfig.XMLLIST;
    const config = headers.content_type.application_json;
    const result = await getRequest(url, config);
    const parsedResult = parseResult(result);
    return parsedResult;
};

export const sendSms = async data => {
    const url = `${servicesBaseUrl+finalConfig.SENDSMS + '/' + data }`;
    const config = headers.content_type.application_json;
    const result = await postRequest(url,data, config);
    const parsedResult = parseResult(result);
    return parsedResult;
};