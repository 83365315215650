import React from 'react';
import {Col, Row} from 'react-bootstrap';

const PointEmptyMessage = () => {
    return (
        <Row>
            <Col md={12} className="text-center my-5">
                <h5>
                    Hiç puan birikmemiş
                </h5>
                <p>
                    Puan biriktirmek için yeni ürünlerimizden <a href="/yeni-urunler">sipariş verebilirisiniz</a>.
                </p>
            </Col>
        </Row>
    )
}

export default PointEmptyMessage;