import * as ProductListAction from '../types/productType';
import { searchAllProducts } from '../api/service/generalService';

export function startFetchingProductList() {
  return {
    type: ProductListAction.START_FETCH_PRODUCT_LIST,
  };
}

export function finishFetchingProductList() {
  return {
    type: ProductListAction.FINISH_FETCH_PRODUCT_LIST,
  };
}

export function errorFetchingProductList() {
  return {
    type: ProductListAction.ERROR_FETCH_PRODUCT_LIST,
  };
}

export function loadProductList(productList) {
  return {
    type: ProductListAction.LOAD_PRODUCT_LIST,
    payload: productList,
  };
}

export function addToCart (cart) {
  return {
    type: ProductListAction.ADD_TO_CART,
    payload: cart,
  };
};

export function freeAddToCart (id,totalPrice) {
  return {
    type: ProductListAction.FREE_PRODUCT_ADD_TO_CART,
    payload: {id,totalPrice}
  };
};

export function removeToCart (cart) {
  return {
    type: ProductListAction.REMOVE_FROM_CART,
    payload: cart,
  };
};

export function removeItemToCart (cart) {
  return {
    type: ProductListAction.REMOVE_CART_ITEM,
    payload: cart,
  };
};

export function removeFreeProductItemToCart (id,totalPrice) {//cart
  return {
    type: ProductListAction.REMOVE_FREE_PRODUCT_CART_ITEM,
    payload:{id,totalPrice} //cart,
  };
};

export function adjustItemQty (itemID, qty) {
  return {
    type: ProductListAction.ADJUST_ITEM_QTY,
    payload: { id: itemID, qty },
  };
};

export function loadCurrentItem (item){
  return {
    type: ProductListAction.LOAD_CURRENT_ITEM,
    payload: item,
  };
};

export function campainCheckState (item){
  return {
    type: ProductListAction.CAMPAING_CHECK,
    payload: item,
  };
};

export const fetchProductList = () => ( dispatch) => {
  dispatch(startFetchingProductList());
  if(JSON.parse(localStorage.getItem('productList')) !== null){
    dispatch(loadProductList(JSON.parse(localStorage.getItem('productList')) || []));
    dispatch(finishFetchingProductList());
    dispatch(addToCart());
  }
  searchAllProducts().then((res) => {
    dispatch(loadProductList(res.data || []));
    dispatch(finishFetchingProductList());
    dispatch(addToCart());
  }).catch((/* err */) => {
    dispatch(errorFetchingProductList());
  });
};

