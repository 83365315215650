import React from 'react';
import {getFavoriteList} from '../api/service/loginServices'
import * as Yup from 'yup';


export const servicesBaseUrl= 'https://api.sahin724.com/api';
export const sliderBaseUrl = 'https://www.sahin724.com/Images/webslider/';
export const productBaseUrl = 'https://www.sahin724.com/products/kategori/';
export const categoryBaseUrl = 'https://www.sahin724.com/products/';
export const productBaseUrl2 = 'https://www.sahin724.com/products/';

export const CategoryList = [
         {
           ID: 1,
           KategoriAdi: "SUCUK",
           KategoriUrl: "/sucuk",
           KategoriImageUrl: "/products/kategori/vs-sucuk-2017.jpg",
           KategoriAciklama:
             "Lezzetini Erciyes Dağı’nın esintisinden alan Şahin Sucuk. Özel Sucuk Fiyatları ile Kayseri'den gelsin.",
            
         },
         {
           ID: 2,
           KategoriAdi: "PASTIRMA",
           KategoriUrl: "/pastirma",
           KategoriImageUrl:
             "/products/kategori/sahin-hafif-yagli-pastirma.jpg",
           KategoriAciklama:
             "Kayseri'nin efsane Pastırması Şahin Pastırma. Özel Pastırma Fiyatları ile Kayseri'den el değmeden kapınıza gelsin.",
         },
         {
           ID: 3,
           KategoriAdi: "MANTI",
           KategoriUrl: "/manti",
           KategoriImageUrl: "/products/kategori/vs-manti-2017.jpg",
           KategoriAciklama:
             "Kayseri Mantısı tarifi aramanıza gerek yok, Şahin güvencesiyle uygun fiyatlı mantı burada.",
         },
         {
           ID: 4,
           KategoriAdi: "KAVURMA",
           KategoriUrl: "/kavurma",
           KategoriImageUrl: "/products/kategori/vs-kavurma-2017.jpg",
           KategoriAciklama:
             "Efsane Şahin Kavurma. Etin en özel bölümlerinden üretilir. Koşulsuz, süresiz iptal ve iade garantisi.",
         },
         {
           ID: 5,
           KategoriAdi: "SALAM",
           KategoriUrl: "/salam",
           KategoriImageUrl: "/products/kategori/vs-altin-kesim-2017.jpg",
           KategoriAciklama:
             "Geniş ürün seçenekleri ile herkes için %100 dana etinden yapılan ürünlerimizi güvenle tüketebilirsiniz.",
         },
         {
           ID: 6,
           KategoriAdi: "SOSİS",
           KategoriUrl: "/sosis",
           KategoriImageUrl: "/products/kategori/vs-sosis-2017.jpg",
           KategoriAciklama:
             "Sosis çeşitleri Şahin'de. Tütsülenmiş sosis, Hellim peynirli ve özel seçenekleri ile Sosis çeşitleri Şahin güvencesi ile kapınıza gelsin.",
         },
         {
           ID: 8,
           KategoriAdi: "AKIL ALMAZ LEZZETLER",
           KategoriUrl: "/ozel-urunler",
           KategoriImageUrl: "/products/sahin-variant-sucuk.jpg",
           KategoriAciklama:
             "Kayseri'nin özel lezzetleri, özel pastırma, sucuk, mantı ve şimdi özel lezzetler kapınıza el değmeden Şahin güvencesiyle gelsin.",
         },
    /*      {
          ID: 12,
          KategoriAdi: "ÖZEL PAKETLER",
          KategoriUrl: "/firsat-paketleri",
          KategoriImageUrl: "/products/sahin-variant-sucuk.jpg",
          KategoriAciklama:
            "Sizin için hazırladığımız özel ürün paketlerimiz avantajlı fiyatlar ile size ulaşsın. ",
        }, */
         {
          ID: 9,
          KategoriAdi: "FÜME",
          KategoriUrl: "/fume",
          KategoriImageUrl: "/products/47.jpg",
          KategoriAciklama:
            "Şahin'den Füme ürünler. Efsane lezzetler, Füme, şarküteri ürünleri.",
        },
        //  {
        //    ID: 9,
        //    KategoriAdi: "YENİ ÜRÜNLER",
        //    KategoriUrl: "/yeni-urunler",
        //    KategoriImageUrl: "/products/47.jpg",
        //    KategoriAciklama:
        //      "Şahin'den altın kesim ürünler. Altın Kesim salam, …ilimler, uygun fiyatlarla pratik atıştırmalıklar.",
        //  },
        //  {
        //    ID: 10,
        //    KategoriAdi: "ÇOK SATAN",
        //    KategoriUrl: "/cok-satan-urunler",
        //    KategoriImageUrl: "/products/sahin-super-secme.jpg",
        //    KategoriAciklama:
        //      "Şahin Sucukları çok satan ürünler, Kayseri'nin efs…urma, Mantı, tıkla en özel ürünler kapına gelsin.",
        //  },
           {
           ID: 9,
           KategoriAdi: "EKONOMİK ÜRÜNLER",
           KategoriUrl: "/ekonomik-urunler",
           KategoriImageUrl: "/products/47.jpg",
           KategoriAciklama:
             "Ekonomik Ürünler kategorimizdeki ürünler özel avantajli fiyatlar ile sunulmaktadir.",
         },
         {
           ID: 1018,
           KategoriAdi: "MARKET",
           KategoriUrl: "/sarkuteri",
           KategoriImageUrl: "/products/tam-yagli-ezine350.jpg",
           KategoriAciklama:
             "Özel peynirler, ezine peyniri, inek ve koyun peyni…hin güvencesiyle. Yeni efsane lezzetleri deneyin",
             // Örnek alt kategori yapısı
            SubCategory: [
              {
                "ID": 1021,
                "KategoriAdi": "Peynir",
                "KategoriUrl": "/peynir",
                "ImageUrl": "https://www.sahin724.com/products/peynir.webp",
                "KategoriImageUrl": "/products/tam-yagli-ezine350.jpg",
                "KategoriAciklama": "Çeşitli lezzetlerde peynirler."
              },
              {
                "ID": 1022,
                "KategoriAdi": "Yağ",
                "KategoriUrl": "/yag",
                "ImageUrl": "https://www.sahin724.com/products/yag.webp",
                "KategoriImageUrl": "/products/tam-yagli-ezine350.jpg",
                "KategoriAciklama": "Farklı yağ çeşitleri."
              },
              {
                "ID": 1023,
                "KategoriAdi": "Zeytin",
                "KategoriUrl": "/zeytin",
                "ImageUrl": "https://www.sahin724.com/products/zeytin.webp",
                "KategoriImageUrl": "/products/tam-yagli-ezine350.jpg",
                "KategoriAciklama": "Çeşitli zeytinler."
              },
              {
                "ID": 1024,
                "KategoriAdi": "Makarna",
                "KategoriUrl": "/makarna",
                "ImageUrl": "https://www.sahin724.com/products/makarnalar.webp",
                "KategoriImageUrl": "/products/tam-yagli-ezine350.jpg",
                "KategoriAciklama": "Farklı makarna türleri."
              },
              {
                "ID": 1025,
                "KategoriAdi": "Çorbalar",
                "KategoriUrl": "/corbalar",
                "ImageUrl": "https://www.sahin724.com/products/corbalar.webp",
                "KategoriImageUrl": "/products/tam-yagli-ezine350.jpg",
                "KategoriAciklama": "Çeşitli çorba çeşitleri."
              },
              {
                "ID": 1026,
                "KategoriAdi": "Bal, Reçel ve Tatlılar",
                "KategoriUrl": "/bal-recel-tatli",
                "ImageUrl": "https://www.sahin724.com/products/bal.webp",
                "KategoriImageUrl": "/products/tam-yagli-ezine350.jpg",
                "KategoriAciklama": "Bal, reçel ve diğer tatlı ürünler."
              },
              {
                "ID": 1027,
                "KategoriAdi": "Atıştırmalıklar",
                "KategoriUrl": "/atistirmaliklar",
                "ImageUrl": "https://www.sahin724.com/products/atistirmaliklar.webp",
                "KategoriImageUrl": "/products/tam-yagli-ezine350.jpg",
                "KategoriAciklama": "Farklı atıştırmalık çeşitleri."
              },
              {
                "ID": 1028,
                "KategoriAdi": "Baharat",
                "KategoriUrl": "/baharat",
                "ImageUrl": "https://www.sahin724.com/products/baharat.webp",
                "KategoriImageUrl": "/products/tam-yagli-ezine350.jpg",
                "KategoriAciklama": "Farklı baharat çeşitleri."
              },
              // {
              //   "ID": 1029,
              //   "KategoriAdi": "Konserveler",
              //   "KategoriUrl": "/konserveler",
              //   "ImageUrl": "https://www.sahin724.com/products/baharat-acipulbiber-min.jpg",
              //   "KategoriImageUrl": "/products/tam-yagli-ezine350.jpg",
              //   "KategoriAciklama": "Farklı konserveler."
              // },
              {
                "ID": 1030,
                "KategoriAdi": "Sos ve Tatlandırıcılar",
                "KategoriUrl": "/sos-tatlandiricilar",
                "ImageUrl": "https://www.sahin724.com/products/soslarvetatlandiricilar.webp",
                "KategoriImageUrl": "/products/tam-yagli-ezine350.jpg",
                "KategoriAciklama": "Çeşitli soslar ve tatlandırıcılar."
              },
              {
                "ID": 1031,
                "KategoriAdi": "Turşu ve Konserveler",
                "KategoriUrl": "/tursu-konserveler",
                "ImageUrl": "https://www.sahin724.com/products/tursuvekonserveler.webp",
                "KategoriImageUrl": "/products/tam-yagli-ezine350.jpg",
                "KategoriAciklama": "Turşular ve konserveler."
              },
              {
                "ID": 1032,
                "KategoriAdi": "İçecek ve Kahve",
                "KategoriUrl": "/icecek-kahve",
                "ImageUrl": "https://www.sahin724.com/products/icecekvekahve.webp",
                "KategoriImageUrl": "/products/tam-yagli-ezine350.jpg",
                "KategoriAciklama": "Çeşitli içecekler ve kahve çeşitleri."
              }
            ]            
         }
       ];


  export const  slugify = (text) => {
        var trMap = {
            'çÇ':'c',
            'ğĞ':'g',
            'şŞ':'s',
            'üÜ':'u',
            'ıİ':'i',
            'öÖ':'o'
        };
        for(var key in trMap) {
            text = text.replace(new RegExp('['+key+']','g'), trMap[key]);
        }
        return  text.replace(/[^-a-zA-Z0-9\s]+/ig, '') // remove non-alphanumeric chars
                    .replace(/\s/gi, "-") // convert spaces to dashes
                    .replace(/[-]+/gi, "-") // trim repeated dashes
                    .toLowerCase();
    
    }

export const handleFavoriteClick = async (e) => {
  var favorite = await getFavoriteList();
  if(favorite.data ){
    if(Array.isArray(favorite.data)){
      if(favorite.data.length > 0 ) { 
        favorite.data.forEach((item) => {
            if(item.UrunID !== e.UrunID) {
              
            }
        });
      }
      else{
        favorite.push(e);
        localStorage.setItem("favorite",JSON.stringify(favorite));
      }
    }
  }
 
  else{
      favorite.push(e);
      localStorage.setItem("favorite",JSON.stringify(favorite));
  }

}


export const homeVideoPart = [
  {
    id:1,
    name:'Eldeğmeden üretiyoruz',
    iframe:<iframe width="100%" height="500" src="https://www.youtube.com/embed/PI4Vxw4rxPQ?controls=0&autoplay=1&loop=1&rel=0&showinfo=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    
  },
  {
    id:2,
    name:'Hijyen',
    iframe:<iframe width="100%" height="500" src="https://www.youtube.com/embed/shw3crfn-WY?controls=0&autoplay=1&loop=1&rel=0&showinfo=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  },
  {
    id:3,
    name:'Pastırmanın hası',
    iframe:<iframe width="100%" height="500" src="https://www.youtube.com/embed/iO2EOGpdllk?controls=0&autoplay=1&loop=1&rel=0&showinfo=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
  },
  {
    id:4,
    name:'Sucuğun hası',
    iframe:<iframe width="100%" height="500" src="https://www.youtube.com/embed/Cfzd3GwHhcY?controls=0&autoplay=1&loop=1&rel=0&showinfo=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
  }

]


export function formatDate(date) {
  date = new Date(date);
  const month = ['Ocak','Şubat','Mart','Nisan','Mayıs','Haziran','Temmuz','Ağustos','Eylül','Ekim','Kasım','Aralık'];
  return (date.getDate().toString() + " " + month[date.getMonth()]+ " "+ date.getFullYear());
}

export const pageListRoute = [
  {
     "ID":1023,
     "sayfaBasligi":"Nasıl Bir Güvence Altındasınız",
  },
  {
    "ID":1038,
    "sayfaBasligi":"Neden Şahin724",
 },
  {
     "ID":1030,
     "sayfaBasligi":"Helal Belge ve Sertifikaları",

  },
  {
     "ID":2,
     "sayfaBasligi":"Güvenli Alışveriş",

  },
  {
     "ID":4,
     "sayfaBasligi":"Müşteri Hattı",

  },
  {
     "ID":5,
     "sayfaBasligi":"Gizlilik ve Koşullar",

  },
  {
     "ID":6,
     "sayfaBasligi":"Kolay Alışveriş",
   
  },
  {
     "ID":7,
     "sayfaBasligi":"Teslimat Koşulları",
 
  },
  {
     "ID":8,
     "sayfaBasligi":"SSS",

  },
  {
     "ID":1020,
     "sayfaBasligi":"İade ve İptal",
 
  },
  {
     "ID":1033,
     "sayfaBasligi":"Kişisel Verilerin Korunması",

  },
  {
     "ID":1034,
     "sayfaBasligi":"Gizlilik Politikası",

  },
  {
     "ID":1035,
     "sayfaBasligi":"Çerez Politikası",

  },
  {
     "ID":1036,
     "sayfaBasligi":"Veri Öznesi Başvuru Formu",
  
  }
]

export const ldJson = (product) => {
  return (
    {
      "@context":"https://schema.org",
      "@type":"Product",
      "productID":product.ID,
      "name":product.UrunAdi,
      "description":product.Aciklama,
      "url":"https://www.sahin724.com"+product.productUrl,
      "image": "https://www.sahin724.com/products/"+product.Url,
      "brand":"Şahin",
      "offers":[
      {
        "@type":"Offer",
        "price":product.Fiyat, 
        "priceCurrency":"TRY",
        "itemCondition":"https://schema.org/NewCondition",
        "availability":"https://schema.org/InStock"
      }]
    }
  );
}

export const AddressValidation = Yup.object().shape({
  AdSoyad: Yup.string()
    .min(2, '')
    .required('Ad ve soyad alanı zorunludur.'),
    CepTel: Yup.string()
    .min(10, 'Hatalı telefon numarası')
    .max(17, 'Hatalı telefon numarası')
    .required('Cep telefon alanı zorunludur.'),
    TcNo: Yup.string()
    .min(11, 'Hatalı TC kimlik numarası')
    .max(11, 'Hatalı TC kimlik  numarası'),
    // AdresAdi: Yup.string()
    // .required('Lütfen adres adı giriniz'),
    // Sehir: Yup.string()
    // .required('Lütfen şehir seçiniz'),
    // Ilce: Yup.string()
    // .required('Lütfen ilçe seçiniz'),
    Adres: Yup.string()
    .min(10, 'Hatalı adres 10 karakterden fazla olmalı.')
    .required('Lütfen adresinizi yazınız'),
    // adrestip: Yup.string()
    // .required('Lütfen adres tipini seçiniz'),
    // VergiNo: Yup.string()
    // .min(10, 'Hatalı vergi numarası')
    // .max(10, 'Hatalı vergi numarası')
});

export const CreateAccountAddressValidation = Yup.object().shape({
  adSoyad: Yup.string()
    .min(2, '')
    .required('Ad ve soyad alanı zorunludur.'),
    cepTel: Yup.string()
    .min(10, 'Hatalı telefon numarası')
    .max(17, 'Hatalı telefon numarası')
    .required('Cep telefon alanı zorunludur.'),
    tcNo: Yup.string()
    .min(11, 'Hatalı TC kimlik numarası')
    .max(11, 'Hatalı TC kimlik  numarası'),
    // AdresAdi: Yup.string()
    // .required('Lütfen adres adı giriniz'),
    // Sehir: Yup.string()
    // .required('Lütfen şehir seçiniz'),
    // Ilce: Yup.string()
    // .required('Lütfen ilçe seçiniz'),
    adres: Yup.string()
    .min(10, 'Hatalı adres 10 karakterden fazla olmalı.')
    .required('Lütfen adresinizi yazınız'),
    // adrestip: Yup.string()
    // .required('Lütfen adres tipini seçiniz'),
    // VergiNo: Yup.string()
    // .min(10, 'Hatalı vergi numarası')
    // .max(10, 'Hatalı vergi numarası')
});

export const eMailValidation = Yup.object().shape({
  username: Yup.string()
    .email('Email formatı hatalıdır')
    .required('E mail zorunludur.')
});


export const creditCardValidation = Yup.object().shape({
  AdSoyad: Yup.string()
    .min(2, '')
    .required('Ad ve soyad alanı zorunludur.'),
    cvc: Yup.string()
    .min(3, 'Tanımsız cvc numarası')
    .max(5, 'Tanımsız cvc numarası')
    .required('Cvc alanı boş geçilemez')
});

export const sginupValitadion = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Ad ve soyad alanı zorunludur.')
    .required('Ad ve soyad alanı zorunludur.'),
    surname: Yup.string()
    .min(3, 'Ad ve soyad alanı zorunludur.')
    .required('Ad ve soyad alanı zorunludur.'),
    email: Yup.string()
    .email('Email formatı hatalıdır')
    .required('E mail zorunludur.'),
    password: Yup.string()
    .min(5, 'Minimum 5 karakter giriniz')
    .required('Ad ve soyad alanı zorunludur.'),
});