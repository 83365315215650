import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { searchByOrderDetail } from '../../../api/service/cartServices';
import { OrderResult } from '../../../constans/svgExport';
import ReactGA from 'react-ga4';
import { searchByOrderNo } from '../../../api/service/generalService';
import { useHistory } from 'react-router-dom';

function OrderCheckout() {

    const [orderInfo, setOrderInfo] = useState(null);
    const history = useHistory();
    useEffect(() => {
        document.body.classList.add('order-result');
        const orderId = localStorage.getItem('orderId');
        localStorage.removeItem('state');
        if (orderId !== null) {
            const getOrderInfo = async () => {
                const res = await searchByOrderDetail(orderId);
                if (!res.errStatus) {
                    setOrderInfo(res.data);
                }
            }
            getOrderInfo();
        }
        gaData()
    });

    const gaData = async () => {
        const resultData = JSON.parse(localStorage.getItem('orderResult'));
        const orderId = localStorage.getItem('orderId');
        const productList = JSON.parse(localStorage.getItem('productList'));
        
        const orderNo = resultData !== null ? resultData.siparis.siparisNo : orderId;
        const orderDetail = await searchByOrderNo(orderNo);
        if (!orderDetail.errStatus) {
            if (orderDetail.data.length > 0) {
                const gaProdutArr = [];
                const faceBookProductArr = [];
                orderDetail.data[0].SiparisKalemleri.forEach(element => {
                    let productFindId = productList.find((e) => e?.Barcode === element.urunKodu);
                    if(!!!productFindId){
                        productFindId = productList.find((e) => e?.ProductName === element.urunAdi);
                    }
                    const product = {};
                    const fProduct = {};
                    product.item_id = productFindId.ProductId;
                    product.price = element.toplamFiyat;
                    product.item_brand = 'Şahin';
                    product.variant = '';
                    product.quantity = element.adet;
                    product.item_name = element.urunAdi;
                    product.coupon = '';
                    fProduct.id = productFindId.ProductId;
                    fProduct.quantity = element.adet;
                    faceBookProductArr.push(fProduct);
                    gaProdutArr.push(product);
                });
                import('react-ga4')
                    .then((x) => x.default)
                    .then((ReactGA) => {
                        ReactGA.event("purchase", {
                            transaction_id: orderNo,
                            currency: 'TRY',
                            value: orderDetail.data[0].KDVDahil + orderDetail.data[0].KargoUcret,
                            items: gaProdutArr,
                            shipping: orderDetail.data[0].KargoUcret,
                            affiliation: 'Online Store',
                            revenue: orderDetail.data[0].KDVDahil + orderDetail.data[0].KargoUcret 
                        })
                        /*ReactGA.ga('send', 'event', {
                            'id': orderDetail.data.ID,
                            'affiliation': 'Online Store',
                            'revenue': orderDetail.data[0].KDVDahil + orderDetail.data[0].KargoUcret,
                            'shipping': orderDetail.data[0].KargoUcret,
                            'coupon': orderDetail.data.KuponNo,
                            'products': gaProdutArr,
                            'eventCategory': 'engagement',
                            'eventAction': 'purchase' 
                        })*/
                    })
                import('react-facebook-pixel')
                    .then((x) => x.default)
                    .then((ReactPixel) => {
                        ReactPixel.init('184192118716429') // facebookPixelId
                        ReactPixel.pageView()
                        ReactPixel.fbq('track', 'Purchase', {
                            content_ids: '[' + orderDetail.data[0].ID + ']',
                            content_type: 'product',
                            value: orderDetail.data[0].KDVDahil + orderDetail.data[0].KargoUcret,
                            currency: 'TRY',
                            constents: faceBookProductArr,
                            compared_product: '',
                            delivery_category: 'in_store'
                        })
                    })
            }
            else {
                history.push('/');
            }
        }

    }

    useEffect(() => {

        ReactGA.initialize('G-6R4VNJYVZG');
        ReactGA.initialize('GTM-TNNVQV2'); 
        //ReactGA.pageview(window.location.pathname + window.location.search);
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Sipariş İçin Teşekkür Ederiz" });

        return () => {
            document.body.classList.remove('order-result');
            // localStorage.removeItem('orderResult');
            localStorage.removeItem('siparisId');
            localStorage.removeItem('orderId');
            localStorage.removeItem('oderData');
            localStorage.removeItem('cartFreeProduct');
            window.location.reload();
        };
    }, []);

    const resultData = JSON.parse(localStorage.getItem('orderResult'));
    const orderId = localStorage.getItem('orderId');
    if (resultData !== null && resultData.siparis !== undefined && resultData.siparis !== null) {
        const orderDate = new Date(resultData.siparis.siparisTarihi);
        const orderDateTime = orderDate.getDate().toString() + "-" + (orderDate.getMonth()+1).toString() + "-" + orderDate.getFullYear();
        return (
            <>
                <Container fluid className="order-result-full-part">
                    <Container className="order-result-normal-part">
                        <div className="order-grid-part">
                            <div className="user-name">
                                {OrderResult("#30ad62")}
                                <div className="order-note">
                                    <h3> {resultData.siparis.user}</h3>
                                    <span>Sipariş İşlemleriniz Tamamlanmıştır.
                                        Teşekkür Ederiz</span>
                                </div>

                            </div>
                            <div className="order-date">

                                <span>Sipariş Tarihi: {orderDateTime}</span>
                                <div className="siparis-no">
                                    <span>Sipariş Numarası</span>
                                    <span>{resultData.siparis.siparisNo}</span>
                                </div>
                            </div>
                        </div>
                        <div className="order-mesaj">
                            <p>
                                Siparişinden hemen sonra; sahin724.com’da tarafına sipariş onayı ile ilgili bir e-posta gönderilir.</p>

                            <p>Siparişinin durumunu ve tüm süreçlerini size özel safınızda bulunan Siparişlerim adımından takip edebilirsin.</p>

                        </div>
                        <div className="order-mail-note">
                            <p>Lütfen elektronik postanızı (e-mail) kontrol ediniz, üyelik ve sipariş bilgileriniz gönderilmiştir.</p>
                        </div>
                    </Container>
                </Container>

            </>
        );
    }
    else {
        if (orderId !== null && orderInfo !== null) {
            const orderDate = new Date(orderInfo.siparisTarihi);
            const orderDateTime = orderDate.getDate().toString() + "-" + (orderDate.getMonth() +1).toString() + "-" + orderDate.getFullYear();
            return (
                <>
                    <Container fluid className="order-result-full-part">
                        <Container className="order-result-normal-part">
                            <div className="order-grid-part">
                                <div className="user-name">
                                    {OrderResult("#23BE72")}
                                    <div className="order-note">
                                        <h3> {orderInfo.user}</h3>
                                        <span>Sipariş İşlemleriniz Tamamlanmıştır.
                                            Teşekkür Ederiz</span>
                                    </div>

                                </div>
                                <div className="order-date">

                                    <span>Sipariş Tarihi: {orderDateTime}</span>
                                    <div className="siparis-no">
                                        <span>Sipariş Numarası</span>
                                        <span>{orderInfo.siparisNo}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="order-mesaj">
                                <p>
                                    Siparişinden hemen sonra; sahin724.com’da tarafına sipariş onayı ile ilgili bir e-posta gönderilir.</p>

                                <p>Siparişinin durumunu ve tüm süreçlerini size özel safınızda bulunan Siparişlerim adımından takip edebilirsin.</p>

                            </div>
                            <div className="order-mail-note">
                                <p>Lütfen elektronik postanızı (e-mail) kontrol ediniz, üyelik ve sipariş bilgileriniz gönderilmiştir.</p>
                            </div>
                        </Container>
                    </Container>
                </>
            )
        }
        else {
            return (
                <>Yükleniyor..</>
            );
        }
    }

}

export default OrderCheckout;