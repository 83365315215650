import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Thumbs } from "swiper/core";
// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/thumbs/thumbs.min.css";
SwiperCore.use([Navigation, Thumbs]);

function ProductSlider(props) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
    <>
      <div className="product-slider-box">
        <Swiper
          style={{
            "--swiper-navigation-color": "#fff",
            "--swiper-pagination-color": "#fff",
          }}
          loop={true}
          spaceBetween={10}
          navigation={true}
          thumbs={{ swiper: window.screen.width > 500 ? thumbsSwiper : ''}}
          className="mySwiper2"
          pagination={{"clickable":true }}
          cssMode={true} 
        >
          <SwiperSlide>
            <img src={props.imageUrl} alt={props.isim} /> 
          </SwiperSlide>
          {props.fotolar.length > 0 && props.fotolar.map(item=>
            (
            <SwiperSlide>
                  <img src={"https://www.sahin724.com/products/"+item.url} alt={props.isim} />
            </SwiperSlide>
             )
             )}
          <SwiperSlide>
            <img src={props.imageUrl} alt={props.isim} />
          </SwiperSlide>
        </Swiper>
        { window.screen.width > 500 ?
          <Swiper
            onSwiper={setThumbsSwiper}
            loop={false}
            spaceBetween={30}
            slidesPerView={4}
            freeMode={true}
            watchSlidesVisibility={true}
            watchSlidesProgress={true}
            className="mySwiper"
          >
           <SwiperSlide>
            <img src={props.imageUrl} alt={props.isim}  />
          </SwiperSlide>
          {props.fotolar.length > 0 && props.fotolar.map(item=>
            (
            <SwiperSlide>
                  <img src={"https://www.sahin724.com/products/"+item.url} alt={props.isim} />
            </SwiperSlide>
             )
             )}
        </Swiper> : '' }
      </div>
    </>
  );
}

export default ProductSlider;
