import React from 'react';
import { Container } from 'react-bootstrap';
import { ReactComponent as ReactLogo } from '../../images/tree.svg';
import ReactGA from 'react-ga4';

const CustomPage = () => {
    //ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA.send({ hitType: "pageview", page:window.location.href, title: "Sosyal Sorumluluk" });
    return(
        <>
            <Container fluid className="custom-page-full-part">
                <Container className="custom-page-normal-part">
                    <div className="custom-page-image-box">
                    <iframe width="100%" height="711" src="https://www.youtube.com/embed/r07v_Ykd4pI?controls=0&loop=1&rel=0&showinfo=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                    <div className="custom-page-description">
                        <ReactLogo />
                        <span>
                            Fidan bağışı yapılacak kurumlar aşağıdaki görsellerde belirtilmiş
                        </span>
                    </div>
                    <div className="custom-vakif-part">
                        <img src="https://sahin724.com/images/0301/fidanbagisi4.jpeg" alt="Tema vakfı" />
                        
                    </div>
                    <div className="custom-video">
                        <iframe width="100%" height="600" src="https://www.youtube.com/embed/5odZ5iRbMxs?controls=0&loop=1&rel=0&showinfo=0" 
                        title="Fidan Bağışı" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                    <div className="custom-read-more">
                        <span>
                            GERÇEKLEŞEN DİĞER SOSYAL SORURUMLULUK PROJELERİMİZ
                        </span>
                        <img src="https://www.sahin724.com/Images/0301/kizilay-logo.png" alt="kızılay logo" />
                        <img src="https://www.sahin724.com/Images/0301/koruncak.png" alt="Koruncal" />
                    </div>
                    <div className="kizilay-aciklama">
                        <img src="https://www.sahin724.com/Images/0301/kizilay.png" alt="kızılay açıklama" />
                    </div> 
                    <div className="custom-video">
                        <iframe width="100%" height="600" src="https://www.youtube.com/embed/DOvyGWpba0s?controls=0&loop=1&rel=0&showinfo=0" 
                        title="Fidan Bağışı" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowFullScreen></iframe>
                    </div>
                </Container>
            </Container>
        </>
    );
}

export default CustomPage;