import React, {  useState,useRef } from 'react';
import { updateUserInfo, } from '../../api/service/loginServices';
import { Form ,Button,Modal, Container,Row,Col} from 'react-bootstrap';
import { Formik } from 'formik';
import KampanyaMetni from '../Policy/kampanyaMetni';
import InputMask from 'react-input-mask';
import loadingGif from '../../images/cart-loader.gif';

function PersonalInformation(props) {
    const form = useRef(null);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    const handleSubmit = async (event) => {
        event.Phone = event.Phone?.replaceAll('(','');
        event.Phone = event.Phone?.replaceAll(')','');
        event.Phone = event.Phone?.replaceAll('-','');
        event.CepTel = event.Phone?.replace(/\s/g, '');
        event.DogumTarihi = event.dateOfBirth;
        const res = await updateUserInfo(event);
        if(!res.errStatus) {
            
        }
        
    };
    if(props.userInfo) {
        return(
            <>
                    <Modal
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        className="cantrat-modal"
                        keyboard={false}
                        >
                        <Modal.Header closeButton>
                        <Modal.Title>724 TİCARİ ELEKTRONİK İLETİ ONAY METNİ VE AÇIK RIZA METNİ</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <KampanyaMetni />
                        </Modal.Body>
                    </Modal>
                    <Formik
                    onSubmit={handleSubmit}
                    initialValues={{
                        Name: props.userInfo.Ad,
                        Surname: props.userInfo.Soyad,
                        Policy:false,
                        Phone: props.userInfo.CepTel,
                        Cinsiyet: props.userInfo.Cinsiyet,
                        dateOfBirth: props.userInfo.DogumTarihi,
                        EMail:props.userInfo.EMail
                    }}
                    >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        touched,
                        errors,
                    }) => (
                    <Form ref={form} noValidate onSubmit={handleSubmit}>
                        <Row className="mb-12 login-input-box">
                            <Form.Group as={Col} md="6" controlId="username" className="name-input">
                                <Form.Control
                                    type="text" placeholder="Adınız"
                                    name="Name"
                                    value={values.Name}
                                    
                                    onChange={handleChange}
                                    isValid={touched.firstName && !errors.firstName}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="surname" className="surname-input">
                                    <Form.Control
                                       type="text" placeholder="Soyadınız" 
                                        name="Surname"
                                        value={values.Surname}
                                        onChange={handleChange}
                                       
                                        isValid={touched.lastName && !errors.lastName}
                                    />
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="surname" className="surname-input">
                            <Form.Select aria-label="Default select example" defaultValue={props.userInfo.Cinsiyet} name="Cinsiyet" onChange={handleChange}>
                                    <option>Cinsiyet</option>
                                    <option value="Erkek">Erkek</option>
                                    <option value="Kadın">Kadın</option>
                                    </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="email" className="email-input">
                                    <InputMask pattern="[0-9]*" name="Phone"mask="0 (999) 999 99-99" defaultValue={props.userInfo.CepTel} placeholder="0 (999) 999 99-99" onChange={handleChange} />
                                </Form.Group>
                                {/* <Form.Group as={Col} md="3" controlId="email" className="email-input">
                                    <InputMask name="dateOfBirth" defaultValue={props.userInfo.DogumTarihi} mask="99/99/9999" placeholder="dd/mm/yy" onChange={handleChange} />
                                </Form.Group> */}
                                <Form.Group as={Col} md="12" controlId="email" className="email-input">
                                    <Form.Control
                                        type="email" placeholder="E posta adresiniz"
                                        name="EMail"
                                        value={values.EMail}
                                        onChange={handleChange}
                                        
                                        isValid={touched.lastName && !errors.lastName}
                                        disabled
                                    />
                                  </Form.Group>
                                <Form.Group as={Col} md="12" controlId="Policy" className="kampanya-checkbox">
                                <Form.Check name="Policy" type="checkbox" defaultChecked={props.userInfo.Sozlesme} onChange={handleChange} />
                                    <Button variant="primary" className="sginup-camps" onClick={handleShow}>
                                        Kampanyalardan ve duyurulardan haberdar olmak istiyorum.
                                    </Button>
                                </Form.Group>
                               
                            </Row>
                           
                            <Button variant="primary" type="submit">
                                 Güncelle
                            </Button>
                        </Form>
                    )}
                    </Formik>
    
                    
            </>
        );
    }
  
    return(
        <>
              <Container fluid className="static-page-full-part">
                    <Container className="static-page-normal-part">
                        <Row>
                            <Col md={12} className="static-page-left-box">
                                <img alt="loading" src={loadingGif} />

                            </Col>
                    </Row>
                </Container>
            </Container>
        </>
    );

}

export default PersonalInformation;